const TranslationsEng = {
    Save: '',
    Cancel: '',
    'GO BACK': '',
    Change: '',
    Confirm: '',
    Name: '',
    Address: '',
    'Post office number': '',
    Town: '',
    Country: '',
    'Country/State': '',
    Notes: '',
    'ADD NEW': '',
    prev: '',
    next: '',
    page: '',
    Select: '',
    CREATE: '',
    'First name': '',
    'Last name': '',
    VIEW: '',
    Close: '',
    Delete: '',
    Update: '',
    ADD: '',
    CANCEL: '',
    View: '',
    Yes: '',
    No: '',
    'Are you sure you want to delete?': '',
    'Invalid value': '',
    'Tag already exist!': '',
    ' EXPORT': '',
    CONFIRM: '',
    SAVE: '',
    'CLOSE VIEW': '',

    //Access control
    'Access point name': '',
    Description: '',
    'Allow guest access, only from rooms with tags': '',
    'no filter': '',
    whitelist: '',
    none: '',
    Guests: '',
    reserved1: 'Reserved keyword which allow all guest to have an access to selected door',
    reserved2:
        'Reserved keyword which allow all holders of temporary self check in code to have an access to selected door',
    Reserved: '',
    'Tag picker': '',
    'Available tags': '',
    'Pinned tags': '',
    'No pinned tags': '',

    //All room events
    'ROOM LOCAL': '',
    GUEST: '',
    'GUEST PHONE': '',
    'CUSTOM ALERT MESSAGE': '',
    'HW MUTE ALARM': 'MUTE ALARM ON HARDWARE',
    'SW MUTE ALARM': 'FORCE MUTE ALARM',
    'NOTE: Sensor alarms can not be muted': "NOTE: Sensor state alarms can't be muted",
    'NOTE: This will force close ticket. Use only if hardware is malfunctioning': '',
    'Please enter the reason for force closing this ticket': '',
    'FORCE CLOSE TICKET': 'Force closing the ticket because of device unresponsiveness',
    'RESPOND TO EVENT': '',
    'Situation center is disabled': '',
    Alarms: '',
    'Room Service': '',
    'Cleaning tracker': '',
    'Loading room events': '',
    'No data': '',
    'SUGGEST RESPONDER': '',
    'CLAIM RESPONSE': '',
    'SELECT RESPONDER': '',
    'EVENT RESPONDER': '',
    'Please, fill the following informations after you have returned from the location': '',
    'EVENT NOTES': '',
    'CLOSE EVENT LOG': '',
    'Event time': '',
    'Event accepted': '',
    Receptionist: '',
    Responder: '',
    'Event closed': '',
    'DISMISS SOS CALL': '',
    'Missing SOS response record. Close this event?': '',
    'TRY TO CLOSE EVENT': '',
    'no responder info': '',

    //Company
    "Field: '": '',
    'VAT or ID number': '',
    "' is not unique!!!!": '',
    Web: '',
    'Add company': '',
    'Edit company': '',
    'Company has ': '',
    ' reseravation(s). Are you sure you want delete?': '',
    'SEARCH FOR COMPANY (by name, town, idNumber)': '',

    //Customers
    'Visa Type And Number': '',
    'Visa Validity Period': '',
    'Date entry in country': '',
    'Entry place': '',
    'Document Validity Period': '',
    'Personal document id': '',
    'Add Customer': '',
    'Edit customer': '',
    Company: '',
    'New Company': '',
    'Birth Date': '',
    'Birth Place': '',
    Gender: '',
    Email: '',
    'Mobile number': '',
    Citizenship: '',
    'Newsletter subscriptions': '',
    Male: '',
    Female: '',
    Undefined: '',
    'Customer has ': '',
    'SEARCH FOR CUSTOMER (by first and last name, email, document id)': '',

    //household
    'Room cleaning orders are generated automatically. Do you want to generate new accounts anyway?': '',
    'Drag employee card from the left and drop them on the room placeholder which needs to be cleaned or inspected. Room access for assigned employee will be granted for the current day and in working hours.':
        '',
    'LAST 10 CYCLES': '',
    'GENERATE NEW LOGS': '',
    'Maid list is empty': '',
    'Cleaning schedule for': '',
    'ROOM / STATUS': '',
    MISSING: '',
    'LOST/FOUND': '',
    MALFUNCTIONS: '',
    'Change cleaning status': '',
    "You can't change the past, but you can learn from it": '',
    'Stage for cleaning': '',
    'Finish cleaning': '',
    'Start cleaning': '',
    'Postpone cleaning': '',
    'Skip cleaning': '',
    'Check minibar': '',
    'Minibar management': '',
    'Save note': '',
    'ITEM NAME': '',
    QUANTITY: '',
    ACTION: '',
    'Minibar from ': '',
    'Active reservation': '',
    From: '',
    To: '',
    Guest: '',
    'Attach to reservation': '',
    'NO ACTIVE RESERVATIONS': '',
    'CONSUMPTION LOG': '',
    'Cleaning requested': '',
    Consumption: '',
    Stock: '',
    'Undo consumption': '',
    Manage: '',

    //Visa Info
    'Nationalities who need visa to enter in country': '',

    //users
    'Add new user': '',
    'Edit user info': '',
    'Card number': '',
    'Select role': '',
    Experience: '',
    'Activate user': '',
    'After user is created, please set the password using the button': '',
    'API KEYS': '',
    ROLES: '',
    "(TEMPORARY KEY! DON'T USE!)": '',
    'Generate token': '',
    Token: '',
    'Valid to': '',
    'User uuid': '',
    'Minimum 6 character is required for password': '',
    'Passwords does not match! Please try again.': '',
    'Password changed': '',
    'New password for this user would be required on next login!': '',
    'Current password': '',
    Password: '',
    'Repeat password': '',

    //whitelist
    'Whitelist name': '',
    Tags: '',
    'New White List': '',
    'Edit White List': '',
    'Delete White List': '',
    'Please, enter all form values!': '',
    'CARD HOLDER NAME': '',
    'changed on ': '',
    'Tags filed can not be empty': '',
    'Add tag...': '',
    'Add tag, then press enter to confirm': '',

    //priceList
    'Room Id': '',
    'Room name': '',
    Pricelist: '',
    'Select a pricelist': '',
    'Accommodation tax': '',
    'No tax': '',
    'Apply per day': '',
    'Multiply with adults number': '',
    'Multiply with children number': '',
    'Person count': '',
    Price: '',
    'PLU is mandatory, min 3 digits': '',
    'PLU must be unique': '',
    'Taken PLUs': '',
    'Please, before continiue, enter at least one tax group.': '',
    'PLU Base': '',
    Tax: '',
    'Max. billable persons': '',
    'Name is mandatory': '',
    'No prices . You should delete this price list and create a new one.': '',
    'Valid from': '',
    'Repeat rules': '',
    'Valid on days': '',
    Weight: '',
    'Parent PLU Base': '',
    Infinite: '',
    'Is active': '',
    'New price list': '',
    'Assign to room': '',
    'NOTE: For every room type, you should create a seperate accommodation price list. You can add sessional and promotional tarrifs by stacking them visualy on infinite timeline - so you can easily track and change pricing and promotion policies.':
        '',
    '*Any modification of pricing policies, have no impact on already saved offers.': '',
    'Price rules not defined': '',
    'Fixed price': '',
    'Up to': '',
    'Update baseline price list': '',
    'Update price list': '',
    'Price per person': '',
    'Accommodation price list': '',
    'Conflicting dates': '',
    'Conflict detected': '',
    'Resolve confilcts by shifting the weight slots on your price lists or by adjusting dates to avoid conflicting price lists. Conflicts affects the automated decision making on accommodation prices':
        '',
    'Stacked price lists on timeline': '',
    'Price lists with a higher weight on the same day, will have a greater importance': '',
    Basis: '',
    'Gross amount': '',
    'Saved successfully': '',
    'Deleted successfully': '',
    'Exchange rates on ': '',
    'Please setup default currency in general settings first.': '',
    'EX. RATES ON DAY': '',
    'Default currency': '',
    'Default currency must have exchange rate of 1 (one).': '',
    'Exchange rate': '',
    'Name:': '',
    Acronym: '',
    'Is enabled': '',
    'New currency': '',
    PLU: '',
    'Next available PLU: ': '',
    Category: '',
    Image: '',
    'Unit of measure': '',
    'Service type': '',
    Enabled: '',
    'Enable item at POS': '',
    'Add new service': '',
    'Edit service': '',
    'Item exist in minibar': '',
    TAX: '',
    'Name or barcode': '',
    'Name or code': '',
    Piece: '',
    'L (liter)': '',
    'KG (kilogram)': '',
    Percentage: '',
    'Tax included in price': '',
    Amount: '',
    'Fixed tax': '',
    'New Tax': '',
    'Update fiscal memory': '',
    Id: '',
    'Netto price': '',
    'Brutto price': '',
    'No repeat rules': '',
    'Always valid.': '',

    //reports
    rejected: '',
    pass: '',
    Reservations: '',
    "Get ghant view of reservations by month. Find out your 'reservation index'.": '',
    'View Report': '',
    'Customers data reports': '',
    Invoices: '',
    'Select and explore invoices.': '',
    'Explore all events for every room. Filter them by date or get CSV export.': '',
    'Room events': '',
    'SOS log': '',
    'Minibar report': '',
    'Access control report': '',
    'Explore door access events. Hotel rooms are not included, only doors.': '',
    'Print number of guests': '',

    //menus
    Rooms: '',
    Controllers: '',
    'SRC templates': '',
    'Alarm settings': '',
    'SOS groups': '',
    'Doors & rules': '',
    Whitelists: '',
    'HW Router': '',
    Customers: '',
    Companies: '',
    'Visa Info': '',
    Settings: '',
    Users: '',
    'Notification service settings': '',
    Updates: '',
    Licence: '',
    Taxes: '',
    Accommodation: '',
    'Price lists': '',
    'Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services.':
        '',
    Currencies: '',
    'Pricelist lookup': '',
    'POS goods': '',
    'Fiscal printers': '',
    'Rooms and hardware': '',
    'Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms.':
        '',
    'Access control': '',
    'Define door, lifts or parking access rules. You can define access whitelist and combine them with any door. Hotel rooms are not managed here.':
        '',
    'Data folders': 'Database',
    'Browse and create customers and companies required by reservation system.': '',
    'Advanced settings': '',
    'Setup language, company informations, currency and modems configuration. Manage API keys.': '',
    'Software updates and licence': '',
    'Update your software. Manage licence keys and view licence informations.': '',

    //notificationTemplate
    'Email service settings': '',
    'SMS service settings': '',

    //pagenotFound
    'PAGE NOT FOUND ERROR': '',

    //roominfo
    'Active room events': '',
    'Nothing to show': '',
    'Room events history': '',
    'Checkout overdue': '',
    'Edit reservation': '',
    'Reservation note': '',
    'Save note update': '',
    'Check out': '',
    'OUT OF SERVICE': '',
    'Room is available': '',
    'Reservation status': '',
    'Customer/Company was deleted': '',
    'Inbound reservations': '',
    'Past 30 days': '',
    'Make a reservation': '',
    'Room settings': '',
    'Detailed room events': '',
    'ADD/REMOVE ITEM': '',
    SERVICE: '',
    STOCK: '',
    'Add/remove': '',
    'Add or remove': '',
    Legend: '',
    'Assign attributes to room ': '',
    'Room does not exist': '',
    'Nothing selected': '',
    'Edit room data': '',
    'Add room': '',
    'Basic room informations': '',
    'Room phone/local': '',
    'Assign room attributes': '',
    'Select atributes': '',
    'You can assign room attributes later on editing room': '',
    'Bed count': '',
    'Spare beds': '',
    'Hardware controller reference (configure at': '',
    'Room price list': '',
    References: '',
    'Search by room name': '',
    Generate: '',
    'New attribute': '',
    "The room has a reference to the reservation. It can't be deleted.": '',
    'Room attributes': '',
    'No tags': '',
    'Extended room settings': '',
    'Dashboard config': '',
    MINIBAR: '',

    //sosgroups
    'ADD GROUP': '',

    //userProfile
    Role: '',
    'CHANGE PASSWORD': '',

    //compontents
    'Choose a file': '',
    Dashboard: '',
    Household: '',
    'Situation center': '',
    Reports: '',
    'System menu': '',
    'Log out': '',
    'Loading ...': '',
    'Not selected': '',
    total: '',
    ' DATE': '',

    //search
    'Search customer or company name, reservation id or room name': '',
    'Search...': '',
    'FIRST NAME': '',
    'LAST NAME': '',
    'COMPANY NAME': '',
    'CHECK IN': '',
    'CHECK OUT': '',
    ROOM: '',
    'Detailed information': '',
    'Reservation details': '',
    Room: '',
    'Check in': '',
    'Adults/childer': '',
    'Invoiced status': '',
    'Invoice id': '',
    'Total value': '',
    Currency: '',
    Reclamation: '',
    'Birth date': '',
    'Birth place': '',
    'E-mail': '',
    'Guest informations': '',
    'Company informations': '',
    'Invoices informations': '',
    'Visa info': '',
    RESERVATIONS: '',
    CUSTOMERS: '',
    'VISA INFO': '',
    COMPANIES: '',
    INVOICES: '',

    //checkoutAndInvoice
    Edit: '',
    // Details: '',
    'Invoice nr': '',
    'Reservation id': '',
    'Invoice issued by': '',
    'Reservation made by': '',
    Date: '',
    'ROOM NUMBER': '',
    Adults: '',
    Children: '',
    // 'Advance payment': '',
    Quantity: '',
    'Unit price': '',
    'Retail price': '',
    'Tax rate': '',
    Discount: '',
    Total: '',
    'TAX RECAPITULATION': '',
    'Tax group': '',
    'NEW PRO FORMA': '',
    'CLEAR CARD CODES': '',
    'ADD CARD CODE': '',
    'Type of payment': '',
    'Fiscal printer': '',
    'Issue receipt': '',
    'Add your company info': '',

    //dashboard
    'Stay over': '',
    'Late check in': '',
    'Checked In': '',
    'Checked Out': '',
    'Room mode': '',
    Cleaned: '',
    'Room available at': '',
    'Incoming guest at': '',
    'There is no rooms with activity today that match selected criteria': '',
    RENTED: '',
    FREE: '',
    'Setup dashboard or add room description': '',
    'SRC settings are not available': '',
    'Display inputs': '',
    Icon: '',
    'Show on dashboard': '',
    'Contact Open color': '',
    'Contact Closed color': '',
    'Blink if Open ': '',
    'Blink if Closed ': '',
    'Make a group reservation': '',
    DAY: '',
    Activity: '',
    Search: '',
    'AVAILABLE FROM': '',
    GROUP: '',
    MAKE: '',
    'GROUP VIEW': '',
    Beds: '',
    DAYS: '',
    TO: '',
    'Paid:Partialy': '',
    Paid: '',
    ' Fully': '',
    'Reservations search...': '',
    Autoblock: '',
    'Early bird at': '',
    'All check-in': '',
    'All check-out': '',

    //reservation
    Days: '',
    'Available rooms': '',
    SELECT: '',
    'SEARCH CUSTOMERS': '',
    'SEARCH COMPANIES': '',
    'APPLY CURRENT TIME & DATE': '',
    'Validation error. Check required inputs.': '',
    error: '',
    DETAILS: '',
    'POSSIBLE CONFLICTS': '',
    'Room reservation': '',
    'Send check in e-mail again': '',
    'Enable self check-in': '',
    'New Guest': '',
    'Group reservation holder': '',
    'Early bird': '',
    Confirmed: '',
    'Checked in': '',
    Canceled: '',
    'Review by rooms': '',
    Status: '',
    'Auto blocked': '',
    'SHOW DETAILS': '',
    'Auto block early bird after': '',
    'ADD RESERVATION HOLDER': '',
    'Reservation notes': '',
    COUNT: '',
    PRICE: '',
    TOTAL: '',
    'Daily total': '',
    'Minibar consumption': '',
    'No recorded minibar consumption': '',
    'This reservation belongs to group, issue advance invoice and remove from group.': '',
    'Select invoice type': '',
    AVANS: '',
    //'Reservations from this group': '',
    'unknown?': '',
    'Access control history': '',
    'Reservation Details': '',
    'Customer Name': '',
    'Company Name': '',
    ACCEPT: '',
    DISMISS: '',
    'Beds/Spare beds': '',
    'Adults/Children': '',
    'VISA AND DOCUMENT DATA FOR  ': '',
    'List is empty': '',
    'VISA AND DOCUMENT DATA': '',
    'Guests informations': '',
    'Go to room view': '',
    'New reservation': '',
    Warning: '',
    'Leave anyway': '',
    'Send self check in code after': '',
    'Self check in valid from': '',
    'Self check in valid to': '',
    'Confirm cancel reservation': '',
    'Are you sure you want change status to CHECK IN': '',
    'Do you want to move this reservation to CONFIRMED state? ': '',
    'NOTE: Current time will be applyed as check in time': '',
    'Confirm checkin': '',
    'Are you sure you want to cancel this reservation?': '',
    CHECKOUT: '',
    'ISSUE INVOICE': '',
    'CHANGE ROOM': '',
    'PRO FORMA INVOICE': '',
    INVOICE: '',
    Closed: '',

    //generalSettings
    'YOUR COMPANY': '',
    'LICENCE HOLDER': '',
    'WARNING: Information from this form will be used to generate licence. After licence is generated, system wide company name could not be changed until new licence is reissued.':
        '',
    'Fiscal POS': '',
    'Enable POS update on pricelist Save': '',
    Enable: '',
    Disable: '',
    'Apply daylight saving time': '',
    'Daylight saving time': '',
    'The command can be executed only 2 times per year!!!': '',
    'Receipt footer': '',
    'Apply footer (Max 4 lines)': '',
    'Gateway integration': '',
    'Find more about IOT Gateway': '',
    here: '',
    'Enable GW 1': '',
    'GW 1 connection': '',
    CLOUD: '',
    'GW1 address (http://address)': '',
    'GW1 location name': '',
    'Enable GW 2': '',
    connection: '',
    'address (http://address)': '',
    'location name': '',
    'LAN address (http://address)': '',
    LOCALISATION: '',
    Locale: '',
    'Other settings': '',
    'Check in time': '',
    'Check out time': '',
    'Enable household warent generation': '',
    'Autogenerate household warrants': '',
    'Daily cleaning start hour': '',
    'Daily cleaning end hour': '',
    'Auto invalidate CONFIRMED reservation after planned CHECK IN (in hours, 0 = disabled)': '',
    'Move invalidate reservation to state': '',
    'AUTO BLOCK': '',
    'Auto BLOCK EARLY BIRD reservations before planned CHECK IN (in hours)': '',
    'Document header (HTML allowed)': '',
    'Document footer (HTML allowed)': '',
    'Invoice notes (HTML allowed)': '',
    'Relaying server (Remote control)': '',
    'QR code validation time after first consumption in minutes': '',
    'Test mode for immediately card code expiration': '',
    'SMTP configuration': '',
    Disabled: '',
    'SMTP host': '',
    Secure: '',
    User: '',
    Pass: '',
    'Locale and currency': '',
    'Company info': '',
    'Company name': '',
    'COST ESTIMATE': '',
    ' per month': '',
    ' activation fee': '',
    'FISCAL CERTIFICATE': '',
    Region: '',
    'Certificate Hash': '',
    Key: '',
    'Issuer Name': '',
    'Serial Number': '',
    'Fiscal certificate': '',

    //licence
    'No licence key': '',
    'Licence form': '',
    'Licence path is not valid!': '',
    'UPDATE LICENCE': '',
    'Remote licence is active !': '',
    'Licence key': '',
    'Paste licence key here ...': '',
    'Encoded licence': '',
    'Paste licence here ...': '',
    'SET LOCAL LICENCE': '',
    'SET REMOTE LICENCE PATH': '',
    'REQUEST LICENCE': '',
    'Licence request data': '',
    'COPY TO CLIPBOARD': '',
    'Company name which will be used system wide (invoices, displays etc.):': '',
    'Please enter email, where the licence should be sent:': '',
    'Administrative email': '',
    'Number of licences (users)': '',
    'Number of rooms': '',
    'Include hardware licences': '',
    'DO NOT INCLUDE': '',
    'INCLUDE HW SUPPORT': '',
    'Devices count (SRC/SOS/GATES)': '',
    'Activation price per device ': '',
    'NEXT STEP': '',
    'SEND REQUEST': '',

    //fiscalPrinters
    'Daily fiscal closure': '',
    'Z REPORT': '',
    'X REPORT': '',
    'X report is daily fiscal closure without registry clear': '',
    'Z report is daily fiscal closure with registry clear': '',
    'Delete all articles from Fiscal Printer': '',
    'Create duplicate of fiscal printer document': '',
    'Select date period or document number': '',
    'Query type': '',
    'Document type': '',
    'All documents': '',
    'Fiscal receipts': '',
    'Reclamation receipts': '',
    'Z report': '',
    'X report': '',
    'Periodic report': '',
    'Issue duplicate': '',
    'Fiscal memory report by date': '',
    'Start date – defines the starting date for the report': '',
    'End date – defines the ending date for the report': '',
    'Select Date': '',
    'Issue report': '',
    'Issue fiscal closure': '',
    'Issue report by date': '',
    'Issue document duplicate': '',
    'Input cash to fiscal printer': '',
    'Output cash from fiscal printer': '',
    'Select fiscal printer...': '',
    'Fiscal request timed out...': '',
    'Input and output of cash': '',
    Type: '',
    'Apply to fiscal printer': '',
    Input: '',
    Output: '',
    'Issue daily report': '',

    //SRC
    'NO ACTIVE SRC REFERENCE': '',
    'Select mode': '',
    'MODE:': '',
    HEATING: '',
    COOLING: '',
    'Automatic changeover': '',
    DISABLED: '',
    Expires: '',
    'Thermostat ': '',
    'Edit SRC proxy': '',
    'Add SRC proxy': '',
    'API key': '',
    'Connected to fiscal': '',
    'BIND SRC 2 ROOMS': '',
    'BROADCAST SETTINGS': '',
    'PLEASE WAIT': '',
    'Migrate devices and rooms from ': '',
    ' to': '',
    'SELECT ONE': '',
    'Are you sure': '',
    'SELECT UNATACHED ROOM': '',
    'Select all': '',
    'ATTACH ROOM': '',
    'Select room': '',
    Attach: '',
    'ATTACH HOTEL ROOM': '',
    'MIGRATE PROXY': '',
    'HOTEL ROOMS': '',
    UNBIND: '',
    Detach: '',
    'UNBINDED SRC CONTROLLERS': '',
    BIND: '',
    'ACCEPT CURRENT RESERVATION EXP.': '',
    'Access code:': '',
    'ACCESS CODES': '',
    'SRC DEVICE COMMUNICATION ERROR!': '',
    'ROOM SETTINGS': '',
    'Room mode adjustment': '',
    'AUTO ADJUSTMENT': '',
    MANUAL: '',
    'Active room mode': '',
    'Enable IO state emitting (RMCU)': '',
    DISABLE: '',
    ENABLE: '',
    'Welcome light duration in seconds (15-60 seconds)': '',
    'General electric ON, after card is pulled off from dispenser (10-60 seconds). It works only if forced check RFID is DISABLED':
        '',
    'Force checking disposed RFID card': '',
    'Debounce entrance for MAID when guest is in the room': '',
    'DISABLE DEBOUNCING': '',
    'ONE EXTRA PASS': '',
    'TWO EXTRA PASSES': '',
    'THREE EXTRA PASSES': '',
    'Enable Mandeks 3D light': '',
    'Door relay holding time (2-15 seconds)': '',
    'Temperature output offset (0-4 °C)': '',
    'BATHROOM HEATING': '',
    'Bathroom wire sensor': '',
    'Target temperature (°C)': '',
    'THERMOSTAT OPTIONS': '',
    'Thermostat Dead Zone': '',
    'Thermostat setpoint differential (for additional heaters, or two step modes)': '',
    'Minimum valve output ON time (minutes)': '',
    'Minimum valve output OFF time (minutes)': '',
    'Minimum FAN ON time (minutes)': '',
    'Fan start booster': '',
    'Proportional band switching point - HIGH fan (80-100)': '',
    'Proportional band switching point - MED fan (30-75)': '',
    'Proportional band switching point - LAW fan (1-15)': '',
    'Fan kick interval (0-90 minutes, 0 = always fan1 running in dead zone, 90-fan kick disabled)': '',
    'Fan start delay (0-180 seconds, 0 is off)': '',
    'EXTERNAL THERMOSTAT': '',
    'GENERAL SETTINGS': '',
    'SRC settings not available for this device. Probably SOS Calling Device.': '',
    'READING EXTENDED SRC SETTINGS': '',
    '(please wait)': '',
    'MAIN THERMOSTAT': '',
    'EMULATION DISABLED': '',
    CLOSED: '',
    'OPEN DOOR': 'OPEN',
    OPEN: '',
    inputs: '',
    outputs: '',
    'SRC IO CONFIGURATION': '',
    'Room clock': '',
    'Wire sensor 1': '',
    'Wire sensor 2': '',
    'Wire sensor 3': '',
    'NOT CONNECTED': '',
    READY: '',
    'NOT AVAILABLE': '',
    'CORRUPTED SETTINGS - PLEASE FIX': '',
    FUNCTION: '',
    'SWITCHING DIFFERENTIAL - HEATING': '',
    'SWITCHING DIFFERENTIAL - COOLING': '',
    'MODBUS ADDRESS': '',
    'IR MODBUS ADDRESS': '',
    'ECO MODE': '',
    'PRECOMFORT MODE': '',
    'COMFORT MODE': '',
    'DEFAULT TEMP.': '',
    'FLOOR TEMPERATURE LIMIT (20-30 Celsius)': '',
    'FIX TO DEFAULT': '',
    '(click on header to expand)': '',
    'Please, select a valid template.': '',
    'EDIT SRC TEMPLATE': '',
    'ADD SRC TEMPLATE': '',
    'Create from config': '',
    'Select room with configured SRC as a template': '',
    'EDIT BASIC SRC TEMPLATE INFO': '',
    'PUSH TEMPLATE': '',
    'Upload failed after ': '',
    ' repeats! Please try again or consult the issue with the supplier!': '',
    'MULTICASTING SRC CONFIGURATION': '',
    'Please wait while the update is in progress. This may take a little longer than usual. Thank you for your patience.':
        '',
    'SRC configuration multicasting may take a while. It depends on number of selected rooms and underlaying modems. Data collisions may occure, so please use late at night or on any other low usage period. In worst case, operation should be repeated.':
        '',
    'All tags': '',
    'PUSH CONFIGURATION': '',
    'Please confirm software update from binary': '',
    'Software will auto restart after updating is finished.': '',
    'Paste link to software package here:': '',
    'Repository updates': '',
    'Binary download': '',
    'Please confirm software update to': '',
    'Please confirm software update': '',
    'AVAILABLE UPDATES': '',
    'REMEMBER ADDRESS': '',
    'FETCH UPDATES LIST': '',

    //otherTranslates
    LOGIN: '',
    'Log in': '',
    ' uploading file': '',
    'Incomming reservations': '',
    'Checked in reservations': '',
    'Canceled reservations': '',
    Note: '',
    'SELECT ROLE': '',
    'RESTORE DEFAULTS': '',
    'User Interface': '',
    'Force reload': '',
    'Issue Reclamation': '',
    Cancelled: '',
    Storno: '',
    POS: '',
    STORNO: '',
    VAT: '',
    'Select fiscal printer': '',
    'Reclamation reason': '',
    Register: '',
    REGISTRATION: '',
    'NEW HOTEL': '',
    'Previous reservation checkout Date': '',
    'Previous reservation status': '',
    'Incoming reservations: No': '',
    'Early bird reservations: No': '',
    'Tags: Filtering by tag remove filters by previously selected tag': '',
    'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes':
        '',
    CONSUMATION: '',
    KEYWORDS: '',
    'General Tax': '',
    Actions: '',
    'Add items to minibar': '',
    'Minibar disabled': '',
    'No privileges': '',
    'System administrator': '',
    'HW administrator': '',
    'Full manager': '',
    'Business manager': '',
    'Household manager': '',
    'Security manager': '',
    'Maid worker': '',
    'GDPR officer': '',
    'Janitor worker': '',
    'Security worker': '',
    'Report access only': '',
    'NO HARDWARE': '',
    'ADD SRC': '',
    CONFIGURATION: '',
    'Hotel rooms and HW': '',
    UPDATE: '',
    'Settings and updates': '',

    //router
    'Dashboard settings': '',
    'Accommodation price list stack': '',
    'Assign pricelist to room': '',
    'Exchange rates': '',
    'POS artikli': '',
    Minibar: '',
    'Household management': '',
    'Your profile': '',
    'Building Management System': '',
    'Room informations': '',
    'Group reservation': '',
    'Reservation administration': '',
    Checkout: '',
    'Pro forma invoice': '',
    'SRC settings broadcast & multicast': '',
    'SRC Proxy configuration': '',
    'Notification Service Settings': '',
    'Notification Templates': '',
    'QR Generator': '',
    'Rooms management': '',
    'API keys management': '',
    'User management': '',
    'General settings': '',
    'Software updates': '',
    'Licence informations': '',
    'Access Control': '',
    'White list access': '',
    'Invoices report': '',
    'Reservations report': '',
    'Passage access control': '',
    'System audit': '',
    'CUSTOMERS DATA REPORT': '',
    'SOS response log': '',
    'Door access report': '',
    TEST: '',
    'Reports menu': '',
    'MINIBAR LOG BOOK': '',
    'Group Reservations': '',
    'Roles management': '',

    'ADVANCE INVOICE': '',
    'PROFORMA INVOICE': '',
    'STORNED INVOICE': '',
    'STORNO INVOICE': '',
    'STORNO ADVANCE INVOICE': '',
    'STORNED ADVANCE INVOICE': '',
    'STORNED PROFORMA INVOICE': '',
    'STORNO PROFORMA INVOICE': '',

    'SOS assistance required': '',
    'Fire alarm': '',
    'Smoke detected': '',
    'Flood detected': '',
    'Motion detected': '',
    'Intrudor detected': '',
    'Minibar opened': '',
    'Room safe opened': '',
    'Do not disturb': '',
    'Cleaning service required': '',
    'Room service required': '',
    'Assistance pending': '',
    'ECO mode': '',
    'COMFORT mode': '',
    'PRE COMFORT mode': '',
    'CLEANING mode': '',
    'Minibar is open more than 5 minutes!': '',

    general_output_name: 'General output',
    welcome_light_output_name: 'Welcome light output',
    door_lock_output_name: 'Door lock output',
    do_not_disturb_output_name: 'Do not disturb output',
    room_service_output_name: 'Room service output',
    thermostat1_cooling_output_name: 'Thermostat 1 cooling output',
    thermostat1_output_name: 'Thermostat 1 output',
    alarm_output_name: 'Alarm output',
    sos_output_name: 'SOS output',
    bathroom_thermostat_output_name: 'Bathroom thermostat output',
    balcony_light_output_name: 'Balcony light output',
    cleaning_service_output_name: 'Cleaning service output',
    sos_input_name: 'SOS input',
    window_state_input_name: 'Window state input',
    thermostat1_input_name: 'Thermostat 1 input',
    thermostat1_cooling_input_name: 'Thermostat 1 cooling input',
    door_state_input_name: 'Door state input',
    balcony_door_input_name: 'Balcony door input',
    do_not_disturb_input_name: 'Do not disturb input',
    room_service_input_name: 'Room service input',
    card_holder_input_name: 'Card holder input',
    fire_alarm_input_name: 'Fire alarm input',
    smoke_alarm_input_name: 'Smoke alarm input',
    motion_alarm_input_name: 'Motion alarm input',
    flood_alarm_input_name: 'Flood alarm input',
    minibar_state_input_name: 'Minibar state input',
    fan1_input_name: 'Fan 1 input',
    welcome_light_input_name: 'Welcome light input',
    cleaning_service_input_name: 'Cleaning service input',

    gsettings_name: 'Name',
    gsettings_hotelName: 'Hotel name',
    gsettings_address: 'Address',
    gsettings_postOfficeNumber: 'Post office number',
    gsettings_town: 'Town',
    gsettings_country: 'Country',
    gsettings_contactEmail: 'E-mail',
    gsettings_idNumber: 'Id number',
    gsettings_notes: 'Notes',

    cl_noActionTaken: 'No action taken',
    cl_cleaningRequested: 'Cleaning requested',
    cl_stagedForCleaning: 'Staged for cleaning',
    cl_cleaningStarted: 'Cleaning in progress',
    cl_cleaningFinished: 'Finished',
    cl_cleaningPostponed: 'Postponed',
    cl_cleaningSkipped: 'Cleaning skipped',
    cl_cleaningInspectionRequired: 'Room inspection required',
    cl_stagedForDeepCleaning: 'Staged for deep cleaning',

    leave_guard_note: 'You have unsaved changes, are you sure you want to leave?',
    leave_guard_note_visa: 'You have uncompleted document or visa visa for guest(s), are you sure you want to leave?',

    'Card code search results': '',
    'Search by ID': '',
    'Created at': '',
    'POS Terminal': '',
    'Made by': '',
    'GROUP DETAILS': '',
    selected: '',
    customerDocumentIdConflictMessage:
        'There is another entry with this document id. Please, change this field or load existing customer data.',
    'LOAD EXISTING DATA': '',
    'POS Terminal Invoice': '',
    'OPEN TERMINAL': '',
    'ALL ITEMS': '',
    'List of consumed items is empty...': '',
    'Search room...': '',
    TERMINAL: '',
    'Items from terminal': '',
    'Items added to reservation ': '',
    'Reservation holder: ': '',
    'SUCCESSFULLY ADDED': '',
    'Select room to save on reservation or issue invoice': '',
    // 'SAVE ON RES.': '',
    'Terminal is disabled... Enable it before use!': '',
    // "Search price lists by tags...": "",
    // "ADD PRICE LIST": "",
    // "HIDE PRICE LISTS": "",
    OIB: '',
    'Business space label': '',
    'Toll device label': '',
    'Sequence marker': '',
    'VAT payer': '',
    'Tax 2': '',
    'Tax 3': '',
    'ATTACH TO GROUP RES.': '',
    'Pin code': '',
    'Invoice discount': '',
    'Enable fiscalisation': '',
    'FISCAL GENERAL SETTINGS': '',
    'READ FROM SCANNER': '',
    'Select document scan': '',
    noDocumentScansNote:
        'No document scans available. Please put document on document scaner and ensure that reading application is up and running.',
    'Enable document scanner for QR Self Check In': '',
    // "Do you want to generate a new qr code for this access point?": "",
    // "Do you want to proceed?": "",
    'Regenerate QR': '',
    'Generate QR': '',
    'After document scanner': '',
    'Message after successfull scan': '',
    'Slice pricelist by tags': '',
    'Select accommodation pricelist': '',
    // 'The advance payment is disabled for the selected amount, issue regular invoice instead!': '',
    // 'Allowed advance payment': '',
    // 'Tax value': '',
    Value: '',
    'Value with discount': '',
    'Additional discount': '',
    // "EDIT GROUP RES.": "",
    'ADD MORE RESERVATIONS': '',
    'Partial payment': '',
    // 'Issue storno invoice': '',
    // 'Storno reason is required': '',
    'Total VAT': '',
    'Total delivered value': '',
    "Valid documents for countries who don't need visa": '',
    'Valid documents for countries requiring visa:': '',
    'Select options...': '',
    'Processing...': '',
    HOME: '',
    'Qty.': '',
    'To pay': '',
    All: '',
    'Search reservation by room or guest card code...': '',
    'ATTACH TO RES.': '',
    'Discount on items': '',
    Operator: '',
    'Attach proforma invoice to room': '',
    'room ': '',
    'Update reservation': '',
    'Remove from group': '',

    'Invoice value': '',
    'Advance payments': '',
    'DELETE RES.': '',
    'Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!':
        '',
    'HOUSEHOLD REPORT': '',
    Fully: '',
    'Rooms and pricelists': '',
    'Tag added': '',
    ROOMS: '',
    PRICELISTS: '',
    'Tag deleted': '',
    'Previous advance payments': '',
    Apply: '',
    Ignore: '',
    'Check out now and move the next reservation.': '',
    'Check out before the start of the next reservation.': '',
    'Check out at initial checkout.': '',
    'Confirm the current date and move the next reservation.': '',
    'Set reservations manually.': '',
    'Do you want to use same pricelist on new room or select new one?': '',
    'Keep pricelist': '',
    'Change pricelist': '',
    NOTIFICATIONS: '',
    'Notification subscriptions': '',
    'Select the events you want to subscribe to': '',
    'Given price offer': '',
    'Given price offer change detected': '',
    'person(s)': '',
    'per day': '',
    'Apply new price': '',
    checkoutDateMissmatchNote:
        'Todays date is different from the one planned in reservation checkout ({X}). Would you like to apply current date ({Z}) as checkout date to the reservation ({Y})?',
    'Reservations from the group': '',
    'Apply to all': '',
    'Reload page': '',
    reloadPageNote1: 'Data loading is taking more than usual.',
    reloadPageNote2: 'Wait a little more or try to reload the page.',
    reloadPageNote3: 'If problem persist, conntact the support.',

    reservationsPanel: 'Reservation panel',
    invoicesPanel: 'Invoices panel',
    roomEventsPanel: 'Room events panel',
    accessControlPanel: 'Access control panel',
    reservationsPanel_general: 'Reservations Report',
    reservationsPanel_condoDays: 'Condo days',
    reservationsPanel_customerReports: 'Customers data reports',
    reservationsPanel_guestsBook: 'Guests book',
    reservationsPanel_guestsBookForEditing: 'Guests book by report for editing',
    invoicesPanel_general: 'Invoices Report',
    invoicesPanel_financialReport: 'Financial Report',
    invoicesPanel_cashRegistarsReport: 'Cash Registars Report',
    invoicesPanel_cashRegistarPaymentsReport: 'Cash registar Payments',
    roomEventsPanel_posTerminalReport: 'Pos Terminal report',
    roomEventsPanel_householdReport: 'Household report',
    roomEventsPanel_general: 'Rooms Report',
    roomEventsPanel_SOSLog: 'SOS log',
    roomEventsPanel_minibarReport: 'Minibar report',
    accessControlPanel_systemAudit: 'System Audit',
    accessControlPanel_general: 'Access Control Report',
    roomsPanel: 'Rooms panel',
    pricePanel: 'Price panel',
    foldersPanel: 'Database',
    advancedSettingsPanel: 'Advanced settings panel',
    licencePanel: 'Licence panel',
    roomsPanel_rooms: 'Rooms',
    roomsPanel_controllers: 'Controllers',
    roomsPanel_posTerminal: 'POS Terminal',
    roomsPanel_cashRegistar: 'Cash registar',
    posConfigurationPanel_tablesLayout: 'POS Tables layout',
    posConfigurationPanel_posTerminal: 'POS Terminals',
    goodsManagementReportPanel_goodsManagement_items: 'Document items',
    goodsManagementReportPanel_entryCalculations: 'RPC items',
    goodsManagementPanel: 'Goods management',
    goodsManagementPanel_documents: 'Documents',
    goodsManagementPanel_entryCalculation: 'Retail price calculation',
    goodsManagementPanel_priceChangeLogs: 'Price change logs',
    goodsManagementPanel_warehouses: 'Warehouses',
    goodsManagementPanel_supplierPriceLists: 'Supplier price lists',
    goodsManagementPanel_stockKeepingUnits: 'SKU Items',
    goodsManagementPanel_stockCard: 'Stock card',
    goodsManagementPanel_skuPluRate: 'SKU PLU Rate',
    roomsPanel_SRCTemplates: 'SRC templates',
    roomsPanel_AlarmAndRoomMode: 'Alarm settings',
    pricePanel_taxes: 'Taxes',
    pricePanel_accomodation: 'Accommodation',
    pricePanel_currencies: 'Currencies',

    posConfigurationPanel_posGoods: 'POS goods',
    dashboard_pos: 'POS terminal',
    dashboard_inventory_revenueBalance: 'Closing revenue balance',
    dashboard_invoices: 'Invoices',
    dashboard_groupInvoices: 'Group invoices',
    dashboard_fiscalTools: 'POS terminal fiscal tools',
    foldersPanel_customers: 'Customers',
    foldersPanel_companies: 'Companies',
    foldersPanel_reservations: 'Reservations',
    advancedSettingsPanel_settings: 'Settings',
    advancedSettingsPanel_users: 'Users',
    advancedSettingsPanel_notificationServiceSettings: 'Notification service settings',
    advancedSettingsPanel_visaInfo: 'Visa Info',
    advancedSettingsPanel_displayConfigInfo: 'POS Display Config',
    advancedSettingsPanel_selfCheckInAdministration: 'Self check in administration',
    licencePanel_licence: 'Licence',
    accessControlPanel_doorsAndRules: 'Doors & rules',
    accessControlPanel_whitelists: 'Whitelists',
    accessControlPanel_documentScannerDevices: 'Document Scanners',

    dashboard_reservations: 'Reservations',
    dashboard_groupReservations: 'Group reservations',
    dashboard_journal: 'Journal',
    dashboard_financialJournal: 'Financial journal',
    dashboard_roomControllers: 'Room controllers',
    dashboard_cardCodeAdmin: 'Card code management',
    accountingAdminPanel_glAccounts: 'Chart of accounts',
    accountingAdminPanel_glReportDefinitions: 'Accounting Report Definitions',
    accountingAdminPanel_typesAndClasses: 'Types and Classes Definitions',
    accountingAdminPanel_automationRules: 'Accounting Automation Rules',
    accountingPanel_accountingJournal: 'Accounting Journal',
    accountingPanel_generalLedger: 'General Ledger Report',
    accountingPanel_glAccountsCards: 'Account cards',
    accountingPanel_analyticalCards: 'Analytical cards',
    accountingPanel_currentGLReport: 'Accounting Report Definitions',
    accountingPanel__finalizedGLReport: 'Finalized Accounting Reports',
    dashboard_reservationConclusion: 'Reservation conclusion',
    foldersPanel_users: 'Users',
    foldersPanel_rooms: 'Rooms',
    foldersPanel_taxes: 'Taxes',
    foldersPanel_accomodation: 'Accommodation',
    foldersPanel_currencies: 'Currencies',
    goodsManagementPanel_pricelistLookup: 'Pricelist lookup',
    foldersPanel_pricelistLookup: 'Pricelist lookup',
    roomsPanel_doorsAndRules: 'Doors & rules',
    roomsPanel_whitelists: 'Whitelists',
    roomsPanel_documentScannerDevices: 'Document scanners',
    advancedSettingsPanel_licence: 'Licence',

    'Analytical cards': '',
    'Accounting Automation Rules': '',
    'Rooms linking': '',
    'Pricelist linking': '',
    'Financial journal': '',
    'Issue invoice': '',
    'Issue advance invoice': '',
    'Issue proforma': '',
    'Close reservation': '',
    'REGULAR INVOICE': '',
    ADVANCE: '',
    PROFORMA: '',
    of: '',
    Invoice: '',
    'More than one reservation in conflict, please adjust them manualy!': '',
    'CLEAR ROOM CODES': '',
    'Fiscal certificate settings are not available!': '',
    'Toll device sellection list is empty!': '',
    'Fiscal printer list is empty!': '',
    'Business space sellection list is empty!': '',
    'Linked invoices': '',
    'click to expand': '',
    'Advance invoices': '',
    'Proforma invoices': '',
    'POS terminal invoices': '',
    'Issue storno invoice': '',
    'Storno invoices': '',
    '(invoice)': '',
    '(advance)': '',
    '(proforma)': '',
    'Genuine invoices': '',
    'Search for a customer or company name': '',
    'CLOSED GROUP RESERVATIONS': '',
    'No reservations': '',
    Active: '',
    'Checked out': '',
    Unknown: '',
    'Guest arrival': '',
    'Due out': '',
    Stayover: '',
    'not paid': '',
    'fully paid': '',
    'Entry date': '',
    Nationality: '',
    Phone: '',
    'Document Id': '',
    'Document Valid Period': '',
    'Reclamation type': '',
    Action: '',
    Regular: '',
    Advance: '',
    Proforma: '',
    'Event id': '',
    'Rule name': '',
    'Start time': '',
    'Day of month': '',
    'Duration minutes': '',
    'End time': '',
    Time: '',
    Source: '',
    'Event duration': '',
    'Assistance duration': '',
    'Room entry after start (min)': '',
    'Reaction time (min)': '',
    'MinibarLog id': '',
    'Item name': '',
    'Previous state': '',
    'New state': '',
    'Log type': '',
    Reservation: '',
    'Created At': '',
    'Total price': '',
    'Cleaning Started': '',
    'Cleaning Finished': '',
    'Duration (m)': '',
    'Cleaning Status': '',
    'Cleaned by': '',
    'Pricelists by tags': '',
    '*drag and drop tags to rooms or pricelists': '',
    'Filter rooms and pricelists by tags': '',
    'Rooms by tags': '',

    goodsManagementPanel_posItems: 'POS items',
    'Retail management': '',
    'Goods management': '',
    'All about goods management process.': '',
    'Retail management reports': '',
    'All about goods management reports.': '',
    'Stock headers': '',
    'SKU Items': '',
    'Retail document items': '',
    'RPC items': '',
    Warehouses: '',
    'Retail price calculation': '',

    'Initial setup required': '',
    'SAVE AND NEXT': '',
    'Does your hotel have multiple floors or buildings?': '',
    'Number of floors': '',
    'Number of rooms ': '',
    'Accept or change rooms name, bed and spare bed count': '',
    floor: '',
    'Mark common attributes for all rooms': '',
    atr_airConditioning: 'Air conditioning',
    atr_kitchen_kitchenette: 'Kitchen/kitchenette',
    atr_privateBathroom: 'Private bathroom',
    atr_balcony: 'Balcony',
    atr_privatePool: 'Private pool',
    atr_terrace: 'Terrace',
    atr_washingMachine: 'Washing machine',
    atr_flatScreenTV: 'Flat screen TV',
    atr_view: 'View',
    atr_coffeeTeaMaker: 'Coffee tea maker',
    atr_kettle: 'Kettle',
    atr_bathtub: 'Bathtub',
    atr_hydromassageBath: 'Hydromassage bath',
    atr_bedForOnePerson: 'Bed for one person',
    atr_doubleBed: 'Double bed',
    atr_minibar: 'Minibar',
    'Apply new checkout date': '',
    'Insert accomodation price for persons number': '',
    'Number of persons': '',
    'Price for': '',
    'Select notification language': '',
    'EMAIL SERVICE SETTINGS': '',
    'SMS SERVICE SETTINGS': '',
    IsEnabled: 'Is enabled',
    ApiPath: 'API path',
    apiPathForRoomFailure: 'API path for room failure',
    ApiObject: 'API object',
    Sender: 'Sender',
    Title: 'Title',
    Body: 'Body',
    SELFSERVICECHECKINHARDWARE: 'HARDWARE SELF SERVICE CHECK IN TEMPLATES',
    SELFSERVICECHECKINSOFTWARE: 'SOFTWARE SELF SERVICE CHECK IN TEMPLATES',
    'Other languages': '',
    'Financial report': '',
    taxGroup: 'Tax group',
    base: 'Base',
    taxValue: 'Tax value',
    totalValueByTax: 'Total value by tax',
    totalBaseValueGenuine: 'Total base',
    totalTaxValueGenuine: 'Total tax',
    totalValueGenuine: 'Total',
    from: 'From',
    to: 'To',
    date: 'Date',
    totalBaseValueAdvance: 'Total base',
    totalTaxValueAdvance: 'Total tax',
    totalValueAdvance: 'Total',
    totalGenuine: 'Total (Genuine)',
    totalAdvance: 'Total (Advance)',
    invoiceNumberAdvance: 'Number of advance invoices issued',
    invoiceNumberGenuine: 'Number of invoices issued',
    'Document Scanners': '',
    Method: '',
    Path: '',
    'DOCUMENT SCANNER DEVICES': 'Document scanner devices',
    'CHECKIN / CHECKOUT': '',
    Everybody: '',
    Rookie: '',
    Intermediate: '',
    Experienced: '',
    'Household report': '',
    'Please, select a price list or apply custom price!': '',
    'Please, select customer or company!': '',
    'Please, select a room!': '',
    'Reservation is closed!': '',
    'Upload a file': '',
    'Basic consent': '',
    Preferences: '',
    'GDPR OPTIONS': '',
    'API keys access disabled': '',
    "Enter room IDs separated with comma, or Card codes next to room ID, seperated with ':'. Example: 1,2:123456789,12":
        '',
    'Other rooms': '',
    'Manual backup': '',
    'WARNING: If using manual mode, make sure you have fast connection with server.': '',
    'Database restoring in progress. Please wait.': '',
    'DOWNLOAD DATABASE': '',
    'Database dumping in progress...': '',
    active: '',
    inactive: '',
    pricePanel_depositRegister: 'Deposit register',
    'Deposit register': '',
    'Undo last offer': '',
    'Previous checkout date': '',
    'Discount value': '',
    'After invoice discount applied': '',
    'Current week': '',
    'Current month': '',
    Today: '',
    'Rooms with requiring attention': '',
    'Welcome to our hotel!': '',
    'We wish you a pleasant stay.': '',
    'To enter, please enter the pin code first.': '',
    'You have successfully checked in to the hotel!': '',
    'You can proceed to your room ': '',
    'Pleasant stay!': '',
    FINISH: '',
    'Self check in logs': '',
    'Your reservation is ': '',
    'Please select one of the following guests first, then scann his document data.': '',
    'Other guests': '',
    'Guest ': '',
    'Scanned data': '',
    DECLINE: '',
    Tomorow: '',
    'Potential hotel occupancy': '',
    days: '',
    'Next week': '',
    'Next month': '',
    Accomplished: '',
    Incomings: '',
    Departures: '',
    'Occupied rooms': '',
    'Method successfully passed': '',
    'Error message': '',
    'SELF CHECK IN LOGS': '',
    'Number of days for encripting guests': '',
    'Include accommodation tax on invoice': '',
    Tax2: '',
    'Daily report': '',
    'Invoice notes (Tax free)': '',
    'Invoice number': '',
    'Invoicing time': '',
    'Method of payment': '',
    'Search by door acces point name': '',
    'NOTIFICATION SETTINGS': '',
    'Set email and sms service settings': '',
    'This is ': '',
    'primary step': '',
    ' for any of self check in types': '',
    'CARD SELF CHECK IN': '',
    Set: '',
    'hardware notification template': '',
    'for card self check in': '',
    'DOCUMENT SCANNER SELF CHECK IN': '',
    Initialize: '',
    'document scanner device': '',
    'for self check in': '',
    'Connect document scanner device to the some': '',
    'access point': '',
    'QR CODE SELF CHECK IN': '',
    'software notification template': '',
    'for qr self check in': '',
    'No assignments': '',
    'Reservation in progress': '',
    'Number of schedule days': '',
    Clear: '',
    TAGS: '',
    'Clear tags': '',
    'Select personel': '',
    'Please select one or more rooms to make a cleaning schedule, or select personel to view their assigned cleaning schedule.':
        '',
    'Now select a cleaning personel to make a schedule for selected rooms.': '',
    Stay: '',
    'Res.': '',
    'Select rooms': '',
    'My reservations': '',
    'Occupancy overview': '',
    'Hotel name': '',
    'Book reg.num.': '',
    'Reference Res.id': '',
    'Birth town': '',
    Daily: '',
    Monthly: '',
    Attributes: '',
    'Select Icon': '',
    'Reference to document scanner device': '',
    'Add tags': '',
    'Is Tax included in price': '',
    'Tax is included in price': '',
    'Tax not included in price': '',
    'Personal days for CONDO user per year': '',
    'Select role...': '',
    'Role selection': '',
    'Group reservation name': '',
    'Group name': '',
    'Acces control QR Generator': '',
    'Self check-in type': '',
    'SELECT GROUP': '',
    'Please select group reservation holder!': '',
    "Checkin date can't be greater than checkout date!": '',
    'Reservations group does not exists!': '',
    'Group does not have any reservation.': '',
    'Reservation is not valid! Please ensure validity before sending to the server!': '',
    'Condo user': '',
    Admin: '',
    'Document scanner': '',
    Maid: '',
    Security: '',
    'Are you sure you want to change room mode?': '',

    DocumentId: '',
    Contact: '',
    'First and last name': '',
    'ENABLED Terminal!': '',
    'DISABLED Terminal...': '',
    'Acc. price rules': '',
    'Short name': '',
    'Reservation is not checked in or closed.': '',
    'First add items in room minibar.': '',
    'Include occupied rooms': '',
    'Access only': '',
    'Room successfully added!': '',
    'Room deleted!': '',
    'Terminal added!': '',
    'Whitelist added!': '',
    'Whitelist deleted!': '',
    'Document scanner device is deleted!': '',
    'Document scanner device added!': '',
    'User is deleted!': '',
    'User successfully added!': '',
    'Tax deleted!': '',
    'Tax added!': '',
    'Accomodation price list deleted!': '',
    'Accomodation price list added!': '',
    'Currency deleted!': '',
    'Currency added! Please set exchange rate for created currency!': '',
    'PosItem inactivated!': '',
    'PosItem added!': '',
    'Customer created!': '',
    'Company created!': '',
    'Company deleted!': '',
    'Notification on failure': '',
    'Minibar invoices': '',
    unknown: '',
    noReservation: '',
    arrival: '',
    dueOut: '',
    stay: '',

    'Cash Registars': '',
    'Cash Registar': '',
    'Open registar': '',
    payIn: 'Pay in',
    payOut: 'Pay out',
    'Pay IN': '',
    'Pay OUT': '',
    'Prev state': '',
    Purpose: '',
    'Cash registar report num. ': '',
    'Cash Registar ': '',
    'Id num.': '',
    'Num.': '',
    'New payment purpose': '',
    'New report': '',
    'CREATE REPORT': '',
    'ADD NEW PAYMENT PURPOSE': '',
    'Are you sure you want make report from ': '',
    ' to ': '',
    'Type of purpose': '',
    'Pay ins and pay outs': '',
    'New cash registar': '',
    'Edit cash registar': '',
    'Open cash registar': '',
    'Cash registars report': '',
    'Cash registar payments': '',
    'Uncollected Bills': '',
    'Change payment status': '',
    'Are you sure you want to set payment status of selected invoices to PAID and create payments?': '',
    'Select period': '',
    'Are you sure you want create proforma?': '',
    'Create proforma': '',

    'Require household inspection after cleaning is complete': '',
    'Fiscal General Settings': '',
    'New item': '',
    'Edit item': '',
    'Doc Type': '',
    'Document number': '',
    'Scan time': '',
    'Bussiness manager': '',
    'Restore default permissions': '',
    'Self check in administration': '',
    noActionTaken: 'No action taken',
    cleaningRequested: 'Cleaning requested',
    stagedForCleaning: 'Staged for cleaning',
    cleaningStarted: 'Cleaning in progress',
    cleaningFinished: 'Finished',
    cleaningPostponed: 'Postponed',
    cleaningSkipped: 'Cleaning skipped',
    cleaningInspectionRequired: 'Room inspection required',
    cleaningInspectionStarted: 'Room inspection started',
    cleaningInspectionFailed: 'Room inspection failed',
    cleaningInspectionPassed: 'Room inspection passed',
    'Filter by tag': '',
    'Filter by attributes': '',
    'List empty...': '',
    'Daily info': '',
    'Weekly and monthly info': '',
    'any selected attribute': '',
    'all selected attributes': '',
    RESERVATIONCONFIRMATION: 'RESERVATION CONFIRMATION TEMPLATE',
    ROOMFAILURE: 'ROOM FAILURE TEMPLATE',
    'Use html template': '',
    RESERVATIONCANCELLATION: 'RESERVATION CANCELLATION TEMPLATE',
    'Comercial reservation': '',
    'Before check-out, you need to go to the reception for invoicing!': '',
    'Available days': '',
    'Used days': '',
    'Reservations total': '',
    Events: '',
    'Room is NOT encoded with condo owner card code!': '',
    'My apartments': '',
    'Are you sure you want to close reservation?': '',
    'If not checked, body and title use system defined values': '',
    'If checked, email is sent like html page': '',
    Message: '',
    'Availability range': '',
    'Select a period on the dashboard toolbar!': '',
    'Next 15 days': '',
    'Next 60 days': '',
    'Preferred language': '',
    'Room failure receiver email': '',
    'Send confirmation email again': '',
    'Send self check in email again': '',
    'Confirm reservation': '',
    'Other language': '',
    'Room price': '',
    'Price per night': '',
    'Accommodation tax rate': '',
    'Accommodation Tax already exist!': '',
    discountedaccommodationtax: 'Discounted accommodation tax',
    'Apply footer (Max 2 lines)': '',

    'All guests': '',
    'Domestic guests': '',
    'Foreign guests': '',
    'Total days': '',
    'Maximum number of days for condo reservations in this room exceeded': '',
    'Remaining number days for condo reservations': '',
    'Current room tags': '',
    'Current room attributes': '',

    'Filter by statuses': '',
    paymentbyInvoice: 'Payment by invoice',
    travelExpenses: 'Travel expenses',
    maintenance: 'Maintenance',
    procurementOfSmallInventory: 'Procurement of small inventory',
    invoiceStorno: 'Invoice storno',
    invoiceIssued: 'Invoice issued',
    'There are no controllers associated with this SRC proxy': '',
    'Select rooms for notification on failure': '',
    'No connected rooms': '',
    'Slack service settings': '',
    'SLACK SERVICE SETTINGS': '',
    'Accommodation invoice': '',
    'The cost of canceling a reservation': '',
    'Cancellation fee': '',
    'Removed from group': '',
    'Send room failure notification for the room ': '',
    "Don't send room failure notification for the room ": '',
    'Cash registar maximum: ': '',
    'Cash registar: ': '',
    'Restart device': '',
    'Full price': '',
    'Partial fee': '',
    'No charge': '',
    'Invoicing method': '',
    'To: (Optionally, if skipped, only a copy of the entered document will be printed)': '',
    'Fiscal tools': '',
    'Cash maximum': '',
    'ADD NEW PAYMENT': '',
    'CONCLUDE CASH REGISTAR REPORT': '',
    'Payment type': '',
    'Payment purpose': '',
    'UNCOLLECTED BILLS': '',
    'Guest/Res': '',
    'Payment status': '',
    'Check to change status': '',
    'Max value is invoice amount': '',
    'Search by invoice id': '',
    PAY: '',
    'New payment': '',
    virman_cashRegistar: 'Virman cash registar',
    'Business conclusion': '',

    'If the square is marked the price can be changed in the terminal': '',
    'Add new price or confirm current:': '',
    'New price': '',
    'Select template for CSV download file': '',
    'Report num.': '',
    'EXPORT CSV': '',
    'CONCLUDE RESERVATIONS LIST': '',
    'Search by item name': '',
    'Predefined notes': '',
    'Please confirm.': '',
    'Remove invoice': '',
    'Tax exemption': '',

    'CONCLUDE FISCALS': '',
    'CONCLUDE CASH REGISTARS': '',
    'SHOW RESERVATION REPORT': '',
    'Adults days': '',
    'Child days': '',
    'Acc.value': '',
    'Acc.VAT': '',
    'AccTax.value': '',
    'AccTax.VAT': '',
    'Other value': '',
    'Other VAT': '',
    'Are you sure you want to conclude reservations?': '',
    'Guests book report': '',
    'Report Header id': '',
    'Accommodation value': '',
    'Accommodation VAT': '',
    'Acc.Tax VAT': '',
    Persons: '',
    'Registered guests report': '',
    'Registered guests': '',
    'Reservation report items': '',
    UNINVOICED: '',
    Item: '',
    'Create invoice': '',
    Calculate: '',
    'Select terminal...': '',
    'Download csv': '',
    'Get export data': '',
    'Assign items to pos terminal ': '',
    'Tables Layout': '',
    'New table layout': '',
    'Edit table layout': '',
    'Background image': '',
    'TABLE ARRANGEMENT': '',
    'Table arrangement': '',
    'Associated terminals': '',
    'Select terminals': '',
    'Subscribed tags': '',
    'Select tags': '',
    'Create new': '',
    'CREATE NEW': '',
    'Number of tables': '',
    Table: '',
    'SHOW ARRANGEMENT': '',
    'Num. of seats': '',
    'Table layout': '',
    'Background position': '',
    'Number of seats': '',
    'Edit table': '',
    'SHOW TABLES LIST': '',
    'Pos goods table view': '',
    'Pos goods list view': '',
    'Search by invoice number or invoice id': '',
    'INV. NUM.': '',
    DATE: '',
    'TOTAL VALUE': '',
    'Accommodation invoices': '',
    'Update customer': '',
    'Do you want to update the customer with new document id?': '',
    Passport: '',
    'Identity card': '',
    Visa: '',
    'Driving licence': '',
    Other: '',
    'Reservation should be checkouted!': '',
    'Min. Stock': '',
    'Guests book': '',
    'FISCAL TOOLS': '',
    'CASH REGISTARS': '',
    'After click, ': '',
    'for every PosTerminal select ': '',
    'for every Cash Registar select ': '',
    'then click on ': '',
    ' to conclude': '',
    'Conclude reservations': '',
    'Conclude cash registar report': '',
    'select ': '',
    'Set min. stock': '',
    'Total currencies': '',
    'Exc.rate': '',
    'Are you sure you want to delete this payment?': '',
    'CREATE NEW GROUP': '',
    'Group invoices': '',
    'Group View': '',
    'CLOSED GROUP INVOICES': '',
    'Group invoice holder': '',
    'Group invoice name': '',
    'Search invoices': '',
    'Search group by groupName...': '',
    'Attach proforma invoice to group': '',
    'Update group': '',
    'ATTACH TO GROUP': '',
    'Customer && Company': '',
    Activate: '',
    'Accommodation tax is calculated on the invoice and pro forma invoice as a separate item. It is not calculated on the advance invoice. (Sugested approach)':
        '',
    'Accommodation tax is calculated only on the invoice as a separate item. It is not calculated on the pro forma invoice and advance invoice. Note (on the pro forma invoice): Accommodation tax is not included in the price of accommodation and is charged at the reception, after the realization of the service.':
        '',
    'Accommodation tax is calculated on all types of invoices as a separate item.': '',
    'Accommodation tax is calculated on all types of invoices within the room price. Note (on the invoice and pro forma invoice): Accommodation tax is included in the price of accommodation. Internal note: The hotel charges for the service at the net price, and the accommodation tax is treated as their internal cost. Therefore, the fee item is not charged directly to the client at all, and no invoice is issued for it at all. The hotel later registers the guests of the services for foreigners, on the basis of which they will receive an invoice for the accommodation tax.':
        '',
    'Invoicing settings': '',
    Approach: '',
    'The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax.':
        '',
    'The expected (entered) price of the room includes the price of the accommodation tax. After entering the expected price, the price of accommodation will be adjusted depending on the price of the accommodation tax. Note: the estimated price of the room will be reduced by the value of the accommodation tax in case of charged unused overnight stay (earlier check-out).':
        '',
    'The expected (entered) price of the room does not include the price of the accommodation tax.': '',
    Pricing: '',
    'Checkout options': '',
    'Apply current time': '',
    'Apply the planned time': '',
    'NOTE: Please select time that will be applyed as check in time': '',
    'Please select a check-out time': '',
    'Checkout confirmation': '',
    'Cash registar and business conclusion': '',
    'All business conclusions in one place.': '',
    'Accom. value': '',
    'Accom. VAT': '',
    'Accom. tax': '',
    'Accom.Tax VAT': '',
    'Group invoice': '',
    'Group made by': '',
    'Invoice header': '',
    'Number of items on a one-sided invoice': '',
    'Number of items on the first page': '',
    'Number of items on the last page': '',
    'Number of items on the middle pages': '',
    'Issuer company name': '',
    'Set note': '',
    'Ref. invoice': '',
    'WARNING!!! You have some reservations that shoud be checked in or checked out. Please edit them if you want to include them in export. Remember - only checked in and checkouted reservations are included in csv export.':
        '',
    'Acommodation Tax Value': '',
    'View reservations details': '',
    'View guests list': '',
    'Show codebook': '',
    'Download edited csv': '',
    genderTypes: 'Gender types',
    visaTypes: 'Visa types',
    documentTypes: 'Document types',
    placesTypes: 'Places types',
    countryTypes: 'Country types',
    'Select codebook': '',
    'Checked in guests': 'Checked in',
    'Checked out guests': 'Checked out',
    Domestic: '',
    Foreign: '',
    Export: '',
    'Select filter for export data': '',
    'Show all': '',
    'Enable POS print': '',
    'Print width (Number of characters)': '',
    'Proxy name': '',
    'SAVE EDITED DATA': '',
    'Customer(s) edited!': '',
    'password reset': '',
    user: '',
    'api key': '',
    'Delete ': '',
    'Confirm ': '',
    'src template': '',
    'proxy restart': '',
    'proxy reset': '',
    'room attribute': '',
    Restore: '',
    'Add room to reservation': '',
    'reservation guest': '',
    'minibar data': '',
    'reservation holder': '',
    reservation: '',
    _tax: 'tax',
    'pos item': '',
    currency: '',
    'exchange rate': '',
    'accommodation price list': '',
    'pos terminal': '',
    company: '',
    'door access point': '',
    'smart room controller': '',
    Nights: '',
    'By countries': '',
    'Please add serial book number and foreign service username': '',
    'Serial Book Number': '',
    'Foreign service username': '',
    'Pos configuration': '',
    'Set terminal settings.': '',
    'Enable side menu': '',
    'Rooms and price lists': '',
    'The guests should have been checked out': '',
    'The guests should have been checked in': '',
    'The arrival of guests is today': '',
    'The departure of guests is today': '',
    Arrival: '',
    Departure: '',
    Journal: '',
    REMINDEREMAILRESERVATION: 'REMINDER CONFIRMATION EMAIL TEMPLATE',
    'day before check in': '',
    'days before check in': '',
    "Don't send": '',
    'Check in reminder email': '',
    'Guest data': '',
    'Minibar general': '',
    'Select rooms and items to apply same minibar and add min stock': '',
    'Clear access codes': '',
    'Skipp cleaning': '',
    'Cleaning note': '',
    'Inspection note': '',
    'Order num.': '',
    'Doc.id': '',
    'Select export type': '',
    Default: '',
    'Guest book': '',
    'Guest list': '',
    'Acc.Tax Value': '',
    'Acc.Tax Value Upon Reg.Guests': '',
    'Invoice num.': '',
    'Group uuid': '',
    Created: '',
    'Prev. state': '',
    'Cash registar': '',
    'Registar max.': '',
    Next: '',
    'Name to display': '',
    'False state label': '',
    'True state label': '',
    'False state icon color': '',
    'True state icon color': '',
    'False state animation blink': '',
    'True state animation blink': '',
    'Self check in test mode': '',
    'Send reservation confirmation to guest': '',
    'Terminal type': '',
    'CHECK USER': '',
    'There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.':
        '',
    'CANCEL ALL': '',
    'Cancel all reservations': '',
    'Do you really want to cancel all this reservations?': '',
    'Are you sure you want to reset room remotely': '',
    'Remote room reset': '',
    'Reset room': '',
    Never: '',
    'Select filter type': '',
    'By registered guests': '',
    'Show reservation conclusions': '',
    'Show registered guests': '',
    'Doc.num.': '',
    'Doc.type': '',
    'Visa Type': '',
    'Visa Num.': '',
    Availability: '',
    'Z reports': '',
    'Count day stay as night': '',
    'Reservation holder': '',

    'Edit warehouse': '',
    'New warehouse': '',
    warehouse: '',
    Barcode: '',
    'Measure code': '',
    'Sales price': '',
    'Active item': '',
    'Add category': '',
    'Warehouse added!': '',
    'Item inactivated!': '',
    item: '',
    'Create a norm': '',
    'Sku item': '',
    'Set multiplier': '',
    Documents: '',
    'Items count': '',
    'Items value': '',
    'Stock unit quantity total': '',
    'Total price value': '',
    'Created by': '',
    Warehouse: '',
    goodsReceivedNote: 'Goods received note',
    goodsReceivingNote: 'Goods receiving note',
    goodsDespatchNote: 'Goods despatch note',
    writeOffGoods: 'Write-off goods',
    'Document header': '',
    'CLOSE DOCUMENT': '',
    'You can not change code!': '',
    'Item does not exist!': '',
    skuItem: 'Item',
    barcode: 'Barcode',
    quantity: 'Quantity',
    price: 'Price',
    purchasePrice: 'Purchase price no tax',
    'This document number already exist!': '',
    totall: 'TOTAL',
    'Purchase price': '',
    'Commited by': '',
    'Commited date': '',
    'Created date': '',
    'Show stock': '',
    'There is no active documents.': '',
    'CLOSED DOCUMENTS': '',
    Normative: '',
    'CREATE NORMATIVE': '',
    taxId1: 'Tax1',
    taxId2: 'Tax2',
    taxId3: 'Tax3',
    skuId: 'Item id',
    'Please add code first!': '',
    grossMargin: 'Gross margin',
    'New retail price calculation': '',
    'Edit retail price calculation': '',
    'There is no active retail price calculations.': '',
    'CLOSED RETAIL PRICE CALCULATIONS': '',
    code: 'Code',
    name: 'Name',
    entryUnitPrice: 'Entry unit price',
    entryPrice: 'Total entry price',
    entryVAT: 'Entry VAT',
    entryVATInfo: 'Entry VAT Info',
    grossMarginPercentage: 'Gross margin',
    grossMarginValue: 'Total GM',
    tax1Total: 'Tax1 total',
    tax2Total: 'Tax2 total',
    tax3Total: 'Tax3 total',
    exitPrice: 'Total retail price Wo VAT',
    itemId: 'Item id',
    'Select close type': '',
    'CLOSE DOCUMENT AND CREATE RETAIL PRICE CALCULATION': '',
    'Edit document': '',
    'New document': '',
    'Document is closed!': '',
    'Please first add document header data!': '',
    'retail price calculation header': '',
    'retail price calculation is closed!': '',
    'Please first add retail price calculation doc number data!': '',
    'CLOSE RETAIL PRICE CALCULATION': '',
    'CLOSE RETAIL PRICE CALCULATION AND CREATE GOODS RECEIVING NOTE': '',
    'Barcode already exists!': '',
    'Barcode is not valid!': '',
    tax1: 'Tax1',
    tax2: 'Tax2',
    tax3: 'Tax3',
    Multiplier: '',
    'Name, code, measure code': '',
    measureUnit: 'Measure unit',
    'with retail price calculation': '',
    Supplier: 'Partner (supplier/customer)',
    'Total transportation': '',
    'Total shipping': '',
    'Total customs': '',
    'Total other costs': '',
    transportation: 'Transportation',
    shipping: 'Shipping',
    customs: 'Customs',
    otherCosts: 'Other costs',
    saleUnitPrice: 'Retail unit price',
    salePrice: 'Total retail price',
    currentSkuPrice: 'Current sku sale price',
    Occupancy: '',
    Home: '',
    'Front desk': '',
    Config: '',
    Details: '',
    'Reset password': '',
    Subscriptions: '',
    'Hotel guests': '',
    'You need help? Check some of frequently asked questions': '',
    'Frequently asked questions': '',
    'You still need help? Contact as at': '',
    'New room': '',
    'Edit room': '',
    'New plan': '',
    'Edit plan': '',
    'New virtual plan': '',
    'Edit virtual plan': '',
    Plans: '',
    'Room Type': '',
    'Save changes': '',
    'Update to': '',
    'Update availability': '',
    'Map to room(s)': '',
    'Select pricelist': '',
    'Virtual rooms': '',
    'Pricing Plan': '',
    Apartment: '',
    Bed: '',
    Unit: '',
    'No board': '',
    Breakfast: '',
    'Half board': '',
    'Full board': '',
    'All inclusive': '',
    Shortname: '',
    'Number of guests': '',
    'Default price': '',
    'Default availability': '',
    'Default board': '',
    'Fixed discount (- -)': '',
    'Fixed Increase (+ +)': '',
    'Percentage discount (- %)': '',
    'Percentage Increase (+ %)': '',
    Variation: '',
    'Select variation': '',
    Closure: '',
    Open: '',
    'Min. Stay': '',
    'Max. Stay': '',
    'No min stay restriction (1)': '',
    'No max stay restriction (1)': '',
    'From/To': '',
    'Work on': '',
    'Restriction Plan': '',
    mon: '',
    tue: '',
    wed: '',
    thu: '',
    fri: '',
    sat: '',
    sun: '',
    DailyPlan: 'Daily',
    'New daily plan': '',
    'Manage Restrictions': '',
    'Room type is for example a Double Room and it is not a real room. A hotel that wants to sell 10 doubles of same standard would need to create a typology Double Room and then specify its availability as 10, which would mean that you have 10 double rooms that you are selling.':
        '',
    "Map to rooms you want to sell online. If you map, for example, three rooms, the availability of the Room Type will automatically become 3. You can change the availability in the 'Manage' section of the interface. Note: if you set the number of guests to 2 when creating the Room Type, then you should map your rooms that are for two people.":
        '',
    'A virtual room is used when you want to sell an existing room with different options and price. A virtual room will share its availability with its `mother room`. A typical example is when you have already a `Double Room`: You can create a virtual room `Double room for single use` as a virtual room of the `Double room`.':
        '',
    "Daily plan - When creating a daily plan, you only need to enter the name of the plan. We determine the prices for that plan in the 'Rooms' interface, where we select the price list for a specific plan. Virtual Plan - A Virtual Plan has the same prices of a 'parent plan', with a percentage variation.":
        '',
    'Setup the same values for one or multiple rooms between a range of dates. You can choose to update: Closure, Availability or Restrictions.':
        '',
    'Guest = the occupancy of the room': '',
    'Map to pricelist(s)': '',
    'HW LAN View': '',
    Tools: '',
    'Select check in and check out date for guest ': '',
    "The guest's stay": '',
    "The guest's stay does not matches with the duration of the reservation": '',
    accommodation: 'Accommodation',
    tax: 'Taxes',
    posTerminal: 'Pos items',
    'Split invoice': '',
    'Next invoice': '',
    'Split to': '',
    'Name, code or barcode': '',
    interWarehouseTransfer: 'Inter warehouse transfer',
    stockMeasureUnit: 'Stock measure unit',
    prevAggWarehouseGoodsQty: 'Last state',
    goodsReceivingQty: 'Goods receiving',
    goodsDespatchQty: 'Goods despatch',
    tempInvoiceQty: 'Invoice goods',
    aggStockRecQty: 'Last state (aggregated)',
    tempStockRecQty: 'Goods received',
    salesPrice: 'Sales price',
    totalQty: 'Total quantity',
    todaysState: 'Todays state',
    skuPrice: 'Item sale price',
    priceForOfferNote: 'Price for offer note',
    stockQuantity: 'Quantity (total - todays)',
    stockPrice: 'Price',
    inventorySurplus: 'Surplus',
    inventoryDeficit: 'Deficit',
    stockDocItemId: 'Doc.item id',
    procurementOrder: 'Procurement order',
    offerNote: 'Offer note',
    inventory: 'Stock report',
    adjustmentByInventory: 'Adjustment by inventory',
    inventoryGoodsReceivingNote: 'Inventory goods receiving note',
    'BELOW THE MINIMUM': '',
    'ABOVE REPLENISHMENT': '',
    invoicedStockNote: 'Invoiced stock note',
    aggregatedWarehouseGoodsData: 'Aggregated warehouse goods data',
    "The guest's stay does not match the duration of the reservation": '',
    'Document view': '',
    Code: '',
    'Stock measure code': '',
    'Minimum stock': '',
    'Replenish stock': '',
    ACTIVE: '',
    INACTIVE: '',
    'Is combo item': '',
    'Warehouse stock': '',
    State: '',
    'SHOW WAREHOUSES': '',
    REPLENISH: '',
    'BELOW THE MIN.': '',
    'ABOVE REPL.': '',
    purchaseMeasureUnit: 'Purchase measure unit',
    purchaseMeasureUnit_stockUnitMultiplier: 'Mulitplier',
    'Purchase measure unit not equal stock measure code': '',
    'Purchase measure unit': '',
    Document: '',
    piecesQuantity: 'Unit quantity (pieces)',
    'Purchase unit to stock unit multiplier': '',
    aggregatedWarehouseGoodsCounter: 'Aggregated warehouse goods counter',
    'Warehouse requisition': '',
    'Is counter': '',
    'Stock replenishment': '',
    'Retail documents': '',
    'RPC data': '',
    'Normative report': '',
    'Mandatory table selection': '',
    'Supplier price lists': '',
    'Add suplement': '',
    'Procurement email': '',
    'Sales email': '',
    'Tech support email': '',
    internalSkuCode: 'Internal SKU code',
    internalSkuBarcode: 'Internal SKU barcode',
    supplierSkuCode: 'Supplier SKU code',
    previousPrice: 'Previous price',
    supplierProcurementPrice: 'Supplier procurement price',
    'View supplier price list': '',
    'New supplier price list': '',
    'By supplier name': '',
    'There is no supplier pricelists': '',
    'From date': '',
    stockUnitQuantity: 'Stock unit quantity',
    purchaseUnitPrice: 'Purchase unit price',
    purchaseValue: 'Purchase value',
    purchaseValueStockUnit: 'Purchase value in stock unit',
    purchaseUnitPriceWithTax: 'Purchase unit price with tax',
    itemValue: 'Item value',
    externalSkuCode: 'External sku code',
    Subcategory: '',
    'Add subcategory': '',
    'number:tag': '',
    'Preparation required': '',
    'Sku item by name, code or barcode': '',
    "RELATED ITEMS'": '',
    SUPLEMENTS: '',
    'All companies': '',
    skuItemName: 'Sku item name',
    'Forbidden edit!': '',
    'Please add company and valid from date!': '',
    'SHOW SUPPLIER PRICE LISTS': '',
    'SHOW DOCUMENTS': '',
    entryPriceValue: 'Entry value',
    salePriceValue: 'Retail price value',
    netoMargin: 'Neto margin',
    'StockKeepingUnit added!': '',
    'Are you sure you want to save? There is no chance to edit.': '',
    'CREATE PROCUREMENT ORDER': '',
    'CLOSE DOCUMENT AND CREATE GOODS RECEIVING NOTE': '',
    'Active supplier price lists': '',
    'Not paid': '',
    'Fully paid': '',
    Duration: '',
    'Connected terminals': '',
    'Invoice type': '',
    'Customers data report': '',
    'Search on page': '',
    Client: '',
    'ID number': '',
    'Search for company (by name, town, idNumber)': '',
    'Search for customer (by first and last name)': '',
    'Total monthly cost': '',
    'Estimated monthly cost': '',
    'Monthly price': '',
    'Cost this month': '',
    'Create invoice for last month': '',
    'Billing invoices': '',
    Billing: '',
    Features: '',
    'Trial preriod until': '',
    'Remaining trial time': '',
    Hours: '',
    Minutes: '',
    January: '',
    February: '',
    March: '',
    April: '',
    May: '',
    June: '',
    July: '',
    August: '',
    September: '',
    October: '',
    November: '',
    December: '',
    'Fetch temperature values': '',
    'Fetch wire sensor values': '',
    'Show temperatures': '',
    'not configured': 'Not configured',
    online: 'Online',
    fading: 'Fading',
    disconnected: 'Disconnected',
    Layers: '',
    'There are no Pos Terminals connected': '',
    counter: '',
    stockValue: 'Item value',
    'Items with more suppliers': '',
    'Items with no one supplier': '',
    'Items with only one supplier': '',
    'Select supplier for items': '',

    'Reservation table': '',
    'By cleaning status': '',
    'By tag': '',
    'By attributes': '',
    Group: '',
    'Notification info': '',
    'change room': '',
    Groups: '',
    'No active group reservations': '',
    'No group invoices': '',
    'Group reservations': '',
    'Group reservation note': '',
    Holder: '',
    'Group status': '',
    'Pay. status': '',
    admin: 'Admin',
    condoUser: 'Condo user',
    documentScanner: 'Document scanner',
    gdprOfficer: 'GDPR officer',
    hwAdmin: 'HW Admin',
    managerFull: 'Full manager',
    managerBussiness: 'Bussiness manager',
    managerHousehold: 'Household manager',
    managerSecurity: 'Security manager',
    receptionist: 'Receptionist',
    maid: 'Maid',
    janitor: 'Janitor',
    security: 'Security',
    reporting: 'Reporting',
    default: 'Default',
    sos: 'SOS',
    posBartender: 'Pos bartender',
    posKitchen: 'Pos kitchen',
    posSupervisor: 'Pos Admin',
    posOnprem: 'Pos on-premise',
    'Document id': '',
    Contacts: '',
    Page: '',
    'Default pricelist - for custom price': '',
    'Cash registars payments': '',
    About: '',
    'Current software version': '',
    'Are you sure you want to close retail price calculation?': '',
    'Are you sure you want to close document?': '',
    'CREATE GOODS RECEIVING NOTE': '',
    'CREATE RETAIL PRICE CALCULATION': '',
    'Save and create pos item': '',
    'Frist and last name': '',
    Read: '',
    'Turnover overview': '',
    'Other fiscal tools': '',
    'POS terminal fiscal tools': '',
    'POS terminal': '',
    'Point of sale': '',
    'There are no conclusions on the selected date': '',
    'Z-Report': '',
    'X-Report': '',
    Cash: '',
    Card: '',
    Add: '',
    Remove: '',
    Proformas: '',
    'Invoice conclusion': '',
    'Invoice reconciliation': '',
    'Invoice conclusions': '',
    'Issued invoices': '',
    'Reservation holder email is required for Self Check In feature!': '',
    'Restore room HW Defaults at checkout': '',
    'Thermostat data': '',
    'Get thermostat data': '',
    'Min COMFORT': '',
    'Max COMFORT': '',
    'Default Thermostat Temperature': '',
    'HW Defaults': '',
    'Room controllers': '',
    'Card code management': '',
    'click to add': '',
    'allocate maids': '',
    'Create customer': '',
    'Create company': '',
    'Edit Tax': '',
    'Edit price list': '',
    'Edit currency': '',
    'Last seen timestamp': '',
    General: '',
    'Guest info': '',
    'Self check in': '',
    'Reservation status must be confirmed': '',
    'No self check in logs': '',
    'Reservation notifications': '',
    'Change room': '',
    'Attach to group': '',
    'Active event': '',
    roomName: 'Room name',
    controller: 'Controller',
    'Main Controller': 'Main Controller',
    'Controller 2': 'Controller 2',
    'Controller 3': 'Controller 3',
    roomId: 'Room ID',
    guest1CardCode: 'Guest 1 card',
    guest2CardCode: 'Guest 2 card',
    guest3CardCode: 'Guest 3 card',
    guest1CardCodeValidTo: 'Guest 1 card valid to',
    guest2CardCodeValidTo: 'Guest 2 card valid to',
    guest3CardCodeValidTo: 'Guest 3 card valid to',
    managerCardCode: 'Manager card',
    managerCardCodeValidTo: 'Manager card valid to',
    securityCardCode: 'Security card',
    securityCardCodeValidTo: 'Security card valid to',
    maid1CardCode: 'Maid 1 card',
    maid2CardCode: 'Maid 2 card',
    maid3CardCode: 'Maid 3 card',
    maid1CardCodeValidTo: 'Maid 1 card valid to',
    maid2CardCodeValidTo: 'Maid 2 card valid to',
    maid3CardCodeValidTo: 'Maid 3 card valid to',
    'Target temperature': '',
    'Thermostat mode': '',

    'Current situation': '',
    Tomorrow: '',
    'rooms / guests incoming': '',
    'rooms / guests outgoing': '',
    'guests currently checked in': '',
    'rooms occupied': '',
    'potential hotel occupancy': '',
    'Add items to your order and they will apprear here': '',
    'Type of invoice': '',
    'Custom price': '',
    'From warehouse': '',
    'To warehouse': '',
    'Auto precomfort mode for room': '',
    previousState: 'Prev. state',
    totalGoodsReceived: 'Total goods received',
    totalGoodsDespatched: 'Total goods despatched',
    totalInovicedStock: 'Total invoiced stock',
    manualTotalInovicedStock: 'Total invoiced stock manual',
    totalCumulativeLoss: 'Total cumulative loss',
    goodsReceiving: 'Goods receiving',
    newState: 'New state',
    inventoryRecord: 'Recapitulation surplus/deficit',
    stockPriceValue: 'Stock price value',
    stockReport: 'Stock report',
    inventoryState: 'New state',
    surplusState: 'Surplus',
    deficitState: 'Deficit',
    accountingState: 'Accounting state',

    'Apply current date': '',
    'Ignore warning': '',
    'Issue the invoice before checking out.': '',
    'Full amount': '',
    'Select a predefined invoice': '',
    'Or write a custom invoice...': '',
    'Invoice note': '',
    'Add invoice': '',
    'Type of paiment': '',
    grossMarginUnitValue: 'GM unit value',
    exitUnitPrice: 'Retail unit price Wo VAT',
    netoUnitMargin: 'Neto unit margin',
    'Add prefix for document type': '',
    'Entry calculation': '',
    'Document number pattern': '',
    'Assemble the pattern': '',
    prefix: '',
    required: '',
    'TABLE VIEW': '',
    'Flexible price': '',
    Inactive: '',
    'Separate Item': '',
    'Cogs percentage': '',
    'Additional margin': '',
    'Current state': '',
    'Pending documents': '',
    'Concluded documents': '',
    'Document status': '',
    currentState: 'Current state',
    stateWithGoodsReceiving: 'State with goods receiving',
    'Previous conclusion': '',
    'Next conclusion': '',
    'Goods received note reference': '',
    'Create new SKU item': '',
    Inventory: 'Stock report',
    'Closing revenue balance': '',
    'Cash turnover': '',
    'Initial cash balance': '',
    'Invoice number pattern': '',
    Year: '',
    '- spaced out': '',
    '/ spaced out': '',
    'Geniune invoices': '',
    'Total revenue': '',
    'Total revenue in cash': '',
    'Total revenue in non cash': '',
    'Invoice count': '',
    'Storno invoice count': '',
    'Tax recapitulation': '',
    'Closing revenue report': '',
    'Attach to group res.': '',
    'Cash withdrawal': '',
    'Cash deposit': '',
    'Shift manager': '',
    'Assign items & tables': '',
    'Closed groups': '',
    'Active groups': '',
    'Closed group reservations': '',
    'Closed group invoices': '',
    'Active group reservations': '',
    'Active group invoices': '',
    category: 'Tags',
    minimumStock: 'Min.stock',
    details: 'Detalji',
    isActive: 'Active',
    Food: '',
    Beverage: '',
    Subtype: '',
    'Financial Journal': '',
    'Goods & services': '',
    'Find sku items by filters and select one or more': '',
    'Inbound stock report': '',
    'Outbound stock report': '',
    'Stock report': '',
    'Total quantity': '',
    'There is no data for selected date(s).': '',
    'Selected date(s):': '',
    Cheque: '',
    Virman: '',
    'Special offer': '',
    'Special offer valid from': '',
    'Special offer valid to': '',
    'Copy item': '',
    'Copy document': '',
    'Doc.invoice number': 'Invoice number',
    'Document invoice number': 'Invoice number',
    'Total purchase price': '',
    'Sku measure unit': '',
    'Pos item': '',
    'Pos measure unit': '',
    'Delay invoice stock dispatched': '',
    'Enable saving orders': '',
    'Enable auto logout': '',
    'Notification System': '',
    'New user': '',
    'Restore selected role': '',
    'total all items entry price': '',
    'Attach all': '',
    'Detach all': '',
    'ATTACH TABLES LAYOUTS': '',
    'ATTACH POS ITEMS': '',
    'Attach tables layouts to pos terminal ': '',
    Columns: '',
    'Search by room name or card code': '',
    'Household plan': '',
    'Tables layout': '',
    'Add new table': '',
    'Associated printers': '',
    'Select users': '',
    'Select printers': '',
    'Associated users': '',
    'No selected': '',
    'ALARM INTERPRETER': '',
    'Change room mode to precomfort automatically at system defined time': '',
    customerUuid: 'Customer uuid',
    firstName: 'First name',
    lastName: 'Last name',
    gender: 'Gender',
    birthDate: 'Birth date',
    birthCountry: 'Birth country',
    documentNumber: 'Document number',
    documentType: 'Document type',
    citizenship: 'Citizenship',
    country: 'Country',
    town: 'Town',
    stayFrom: 'Stay from',
    timeStayFrom: 'Time stay from',
    foreseenStayUntil: 'Stay until',
    timeEstimatedStayUntil: 'Time stay until',
    paymentCategory: 'Payment category',
    arrivalOrganisation: 'Arrival organisation',
    offeredServiceType: 'Offered service type',
    facility: 'Facility',
    'Register guests on eVisitor': '',
    checkInDate: 'Check in',
    checkOutDate: 'Check out',
    foreignServiceUsername: 'Foreign service username',
    bookSerialNumber: 'Book serial number',
    'Local offset time': '',
    'Relogin after': '',
    'Enter username for eVisitor login': '',
    'Enter password for eVisitor login': '',
    'Enter api key for eVisitor login': '',
    cumulativeLoss: 'Cumulative loss',
    'Cumulative loss in %': '',
    'Parent stock keeping unit': '',
    'Normative table': '',
    'Add document': '',
    'Visa information is not filled out': '',
    'Here is a list of checked in and closed reservations since last conclude. Please conclude reservations list to create guest book report.':
        '',
    'No checked in or closed reservations since last conclude!': '',
    'Show guests book by reports': '',
    'Show guests book list': '',
    'Reservations by guests book report': '',
    'Guests book by closed reports': '',
    'Guests book list': '',
    'All reservations': '',
    'Checkedin reservations': '',
    'Checked out reservations': '',
    'Guests book by report for editing': '',
    'Mark guests data as send to portal': '',
    'Mark guests data': '',
    'Holder name': '',
    'Calculated room accommodation': '',
    'Accommodation info': '',
    'Visa data modal': '',
    'Check pos display config for access!': '',
    'Birth country': '',
    noRepeat: 'no repeat',
    weeklyOnDays: 'weekly on days',
    monthly: 'monthly',
    yearly: 'yearly',
    'Door control': '',
    'Door relay control': '',
    'Allow guest access for rooms with tags': '',
    Whitelist: '',
    'Selfcheck in settings': '',
    'Access control tag picker': '',
    'Whitelist tag picker': '',
    'Time zone': '',
    'Set room mode to precomfort automatically before estimated check in time (in hours)': '',
    'User id': '',
    'Check room id searching by room name': '',
    'Event name': '',
    'Virtual plan(s)': '',
    'There are no virtual plans for plan': '',
    'virutal plan(s)': '',
    'virutal room(s)': '',
    Mapping: '',
    'Map to rooms': '',
    'Virtual room(s)': '',
    'There are no virtual rooms for room': '',
    'Search rooms': '',
    'Enter value': '',
    'Choose restriction plan': '',
    'Choose pricing plan': '',
    'Set price': '',
    'Last sync': '',
    'Open/Closed': '',
    'Min stay/Max stay': '',
    'Room/Day': '',
    'Reservation creation time': '',

    Finance: '',
    'General ledger': '',
    'Chart of accounts': '',
    financiallyState: 'Financially state',
    financiallyTurnover: 'Financially turnover',
    'Accounting Report Definitions': '',
    Account: '',
    'New gl account': '',
    'New accounting report definition': '',
    'Edit accounting report definition': '',
    'Edit gl account': '',
    account: 'Account',
    'Jump to today': '',
    'gl account': '',
    'Chart of accounts table view': '',
    'SHOW GL ACCOUNTS LIST': '',
    'JSON DATA IMPORT': '',
    'Paste your JSON and connect properties': '',
    'JSON Data': '',
    'Add your key for account': '',
    'Add your key for name': '',
    'Gl Documents': '',
    Class: '',
    'Active accounting journal': '',
    'Locked accounting journal': '',
    'There is no active gl documents.': '',
    'View general ledger document': '',
    'Edit general ledger document': '',
    'External number': '',
    Module: '',
    'Issue date': '',
    'Posting date': '',
    'Due date': '',
    Credit: '',
    'Credit transactions': '',
    Debt: '',
    'Debt transactions': '',
    Saldo: '',
    credit: '',
    debt: '',
    'GL Document': '',
    'SHOW GL DOCUMENTS': '',
    'CLOSE GENERAL LEDGER DOCUMENT': '',
    'Select class': '',
    inboundDocument: 'Inbound document',
    outboundDocument: 'Outbound document',
    internalDocument: 'Internal document',
    protocols: 'Protocols',
    financialVoucher: 'Financial Voucher',
    paymentDocument: 'Payment document',
    otherDocuments: 'Other documents',
    foreignCurrencyDocuments: 'Foreign currency documents',
    incomingAccounts: 'Incoming accounts',
    outgoingAccounts: 'Outgoing accounts',
    'General Ledger Report': '',
    'Accounting Journal': '',
    'Account cards': '',
    'GL Report line for ': '',
    'Line name': '',
    'Line display order': '',
    'GL accounts list': '',
    'Close view': '',
    'Search gl account': '',
    'Connect properties and save data': '',
    'CSV Content': '',
    'Import CSV file': '',
    'Subtract year': '',
    'Add year': '',
    'Apply to days': '',
    'Plans & Rooms': '',
    Restrictions: '',
    'New virtual room': '',
    'Chart of accounts list view': '',
    'Reports for period': '',
    'Finalized Accounting Reports': '',
    aggregationType: 'Aggregation type',
    closingState: 'Closing state',
    sum: 'Sum',
    'GL Report document': '',
    Years: '',
    'Accounts list': '',
    'Line ref': '',
    'Opening credit': '',
    'Opening debt': '',
    Months: '',
    'Selected year': '',
    'Selected month': '',
    'Closing credit': '',
    'Closing debt': '',
    'Credit balance': '',
    'Debt balance': '',
    'Balance D-P': '',
    'Balance P-D': '',
    'Show column Balance D-P': '',
    'Show column Balance P-D': '',
    'GL Report lines for ': '',
    'GL Report lines': '',
    'Generate report': '',
    'Select year and month for report': '',
    'GL Report doc list': '',
    Month: '',
    'Edit/view gl document': '',
    'New general ledger document': '',
    'SAVE REPORT DEFINITION': '',
    'Add seperator': '',
    'Find gl accounts and select one or more': '',
    'Show months': '',
    'Show years': '',
    'Total (EUR)': '',
    notPaid: 'not paid',
    'Group reservation details': '',
    conclusionType: '',
    Invoiced: '',
    'POS Terminals': '',
    'POS Tables Layout': '',
    'Value or Variation': '',
    'Set value': '',
    'Suggestion - Enter the maximum availability that corresponds to the total number of connected rooms. For example, if you have linked 4 rooms, it is advisable to set the availability to 4. In case some rooms are occupied, the system will automatically update the availability to avoid the risk of overbooking.':
        '',
    'GL Report document yearly conclusion ': '',
    'CSV file should have this structure': '',
    '1. row - headers': '',
    'KeyForAccount Seperator KeyForName': '',
    '2. row - first pair key(number)-value': '',
    'account seperator name': '',
    '3. row - second key-value pair': '',
    Example: '',
    'CLOSE REPORT': '',
    'Are you sure you want to close yearly conclusion': '',
    'The reservation is from one of the booking channels, and you can transfer it only if you have a room that is of the same type as this room.':
        '',
    'Spec.offer': '',
    'Date format': '',
    'Price change logs': '',
    'There is no active price change logs.': '',
    skuStockCurrent: 'Current stock',
    previousSkuPrice: 'Prev. unit price',
    previousSkuPriceValue: 'Prev. price value',
    newSkuPrice: 'New unit price',
    newSkuPriceValue: 'New price value',
    taxAdjustment: 'Tax adj.',
    priceAdjustment: 'Price adj.',
    'New price valid from': '',
    'Pending price change logs': '',
    'Closed price change logs': '',
    'Price change log view': '',
    'Edit price change log': '',
    'New price change log': '',
    'Price change log header': '',
    'Price change log status': '',
    'CLOSE PRICE CHANGE LOG': '',
    'Are you sure you want to close price change log?': '',
    'Copy price change log': '',
    consignmentWarehouse: 'Consignment warehouse',
    retailWarehouse: 'Retail warehouse',
    householdWarehouse: 'Household warehouse',
    'Assign to Pos Terminal': '',
    receptionWarehouse: 'Reception warehouse',
    'Warehouse replenishment': '',
    'Record on reservation': '',
    'Inventory adjustment': '',
    'SKU items list view': '',
    'SKU items table view': '',
    replenishStock: 'Replenish stock',
    'Stock value': '',
    'Pending calculations': '',
    'Concluded calculations': '',
    'Sku sale price': '',
    'Print Preview': '',
    'Due Out': '',
    Print: '',
    Assigments: '',
    Assign: '',
    'Auto suggest cleaning': '',
    'Auto suggest inspection': '',
    'Requires assigment': '',
    Empty: '',
    'Include empty rooms': '',
    'Auto schedule the selected maid(s) evenly': '',
    'Assign selected maid(s) to selected rooms': '',
    'Working hours': '',
    Utilization: '',
    'Parent terminal': '',
    'No parent pos terminal': '',
    balanceRule: 'Balance rule',
    creditMinusDebt: 'Credit - debt',
    debtMinutCredit: 'Debt - credit',
    'Balance rule': '',
    Kitchen: '',
    'Accounting Admin': '',
    Accounting: '',
    'Reservations conslusion': '',
    'POS terminal report': '',
    'Cash registars': '',
    'Retail price calculations report': '',
    'Generate new log': '',
    'Pending table': '',
    'Storno invoice': '',
    'Dashboard reservation activity': '',
    'Close group (Activate group)': '',
    'SRC Proxy controllers settings': '',
    'Fiscal settings': '',
    'Company info settings': '',
    'Handle reservations': '',
    'Pos Invoices': '',
    'Manage household': '',
    'Dashboard reservation timeline': '',
    'GatewayIntegration settings': '',
    'Update SRC acccess codes': '',
    'Offline POS Thermal Printer Info': '',
    'State and conclusion': '',
    'Accounting Report Definition': '',
    'Edit/view general ledger document': '',
    'Report name': '',
    'Aggregation type': '',
    'on day': '',
    ' in': '',
    'Select accounting report': '',
    'Select year': '',
    'Select month': '',
    'Yearly conclusion': '',
    'Select report type, year and month': '',
    'Advance payment time': '',
    'For role': '',
    'Closing balance': '',
    'Select roles to show system messages': '',
    'Select roles to not show system messages': '',
    'Condo reservations': '',
    Section: '',
    Documentation: '',
    'Help and documentation': '',
    'Help and support': '',
    'Struggling or have questions? Our Help Center is here for you! Explore a wide range of topics and find the assistance you need...':
        '',
    'Do you have an issue to report? For a prompt response and assistance': '',
    'Report an Issue': '',
    'For any further questions or information, feel free to contact us via email': '',
    'Delete document': '',
    'Lost / Found': '',
    Maintainance: '',
    Reception: '',
    Conversation: '',
    'Task templates': '',
    'Task Templates': '',
    'Room status': '',
    'Room Status': '',
    multiple: '',
    'Task estimated time': '',
    Cleaning: '',
    Inspection: '',
    'Cleaning Times': '',
    'Cleaning times': '',
    'Household reports': '',
    'By room status': '',
    'By user': '',
    'By room': '',
    'Average time': '',
    'Estimate time': '',
    'Less than the expected average time': '',
    'More than the expected average time': '',
    'Nr. of rooms': '',
    'Expected time spent': '',
    'Actual time spent': '',
    'Time difference': '',
    'Assigned user': '',
    'Task status': '',
    'Task start': '',
    'Task end': '',
    Tasks: '',
    'By user assigned': '',
    'By task status': '',
    'By room tags': '',
    'Nr. of tasks': '',
    History: '',
    'Report lost': '',
    'Report found': '',
    'Cleaning tasks': '',
    'Not assigned': '',
    Assigned: '',
    Pending: '',
    Postponed: '',
    Finished: '',
    'Task List': '',
    Staged: '',
    Started: '',
    Skipped: '',
    Requested: '',
    'Welcome to ': '',
    'Please enter pin code that you received via E-mail or SMS.': '',
    'Enter PIN': '',
    'Your reservation is #': '',
    'Scanning guest documents': '',
    "You have successfully entered the PIN code! In order to complete the self-check-in at the hotel, it is necessary to scan the guests' documents.":
        '',
    'Select guest': '',
    'Please select one of the guests offered to scan their document.': '',
    'Scan document': '',
    'Scan the document using the device located next to the computer for guest ': '',
    'Select the correct guest data': '',
    'Found ': '',
    ' guests with similar information. Select the appropriate option or create a new one.': '',
    'Create a guest with the following information': '',
    'Or choose the appropriate option': '',
    'You have successfully completed self-check-in!': '',
    'You may proceed to your room ': '',
    'We wish you a pleasant stay!': '',
    Start: '',
    'Step ': '',
    ' of 3': '',
    'Condo days': '',
    'Personal days per year': '',
    'Personal days accomplished': '',
    'Rest of the days': '',
    'Filter pricelists': '',
    'Custom price (opt.)': '',
    'Pos items list view': '',
    'Pos items table view': '',
    'Please select?': '',

    'Underfloor heating without fan coil units': '',
    'New task': '',
    'By Date': '',
    Content: '',
    Reply: '',
    'User assigned': '',
    Lost: '',
    Found: '',
    'User created': '',
    'New comment': '',
    'New found': '',
    'New lost': '',
    'New thread': '',
    'Mark as completed': '',
    'Undo completion': '',
    taskRequested: 'Task requested', //atomatski sa SRC-a
    stagedForTask: 'Staged for task', //znači da se netko obvezao na čišćenje - čistačica.... ili je soba deklarirana za čišćenje sa backenda
    taskStarted: 'Started',
    taskFinished: 'Finished',
    taskPostponed: 'Postponed', //samo ako je bila stageana
    taskSkipped: 'Skipped',
    'New task template': '',
    'Check Out Group ': '',
    'Check Out Room ': '',
    'Regular invoice': '',
    'Split & issue...': '',
    'Edit note': '',
    'Edit invoice': '',
    'Fiscalize invoice': '',
    Fiscalize: '',
    'Without assigment': '',
    Finish: '',
    Postpone: '',
    'Cleaning history': '',
    Saving: '',
    'Task list': '',
    Back: '',
    'Assign cleaning': '',
    'Select tasks': '',
    'Assign inspection': '',
    'Task selection': '',
    'Auto split by rooms/groups': '',
    'Inspection schedule for': '',
    'DUE OUT / ARRIVAL': '',
    'RES. STATUS': '',
    'Assigned to': '',
    'Last cleaned': '',

    'The controller is not bound to the room.': '',
    'Unbind SRC controller from room?': '',
    Items: '',
    'Total credit': '',
    'Total debt': '',
    'No documents for selected period': '',
    'Show summary': '',
    grossBalance: 'Gross Balance',
    trialBalance: 'Trial balance',
    Boards: '',
    Board: '',
    documentName: 'Document name',
    'Add new room info': '',
    'Edit room info data': '',
    'Add tags to room': '',

    proformaInvoice: 'Proforma invoice',
    proformaDispatchedInvoice: 'Proforma dispatched invoice',
    advanceInvoice: 'Advance invoice',
    regularInvoice: 'Regular invoice',
    'No action taken': '',
    breakfasts: '',
    'First meal': '',
    'Second meal': '',
    'Third meal': '',
    'Consumption records': '',
    'Board consumption': '',
    'Board Consumption': '',
    'Board consumption report': '',
    Lunch: '',
    Dinner: '',
    'Show guests': '',
    singleUse: 'Single use',
    doubleUse: 'Double use',
    tripleUse: 'Triple use',
    quadUse: 'Quad use',
    'Household refill setup': '',
    'Single room': '',
    'Double room': '',
    'Triple room': '',
    'Apartment(4)': '',
    'Apartment(5)': '',
    'Add exception': '',
    Refill: '',
    Laundry: '',
    householdWarehouseMinibar: 'Minibar warehouse',
    'Potential refill': '',
    'Initial state': '',
    'Manage warehouses': '',
    'Written off': '',
    retailPriceCalculation: 'Retail price calculation',
    goodsReceivingNotePriceValue: 'Goods receiving note price value',
    goodsReceivingNoteTaxValue: 'Goods receiving note tax value',
    goodsDespatchNotePriceValue: 'Goods despatch note price value',
    goodsDespatchNoteTaxValue: 'Goods despatch note tax value',
    writeOffGoodsPriceValue: 'Write-off goods price value',
    interWarehouseTransferPriceValue: 'Inter warehouse transfer price value',
    procurementOrderPriceValue: 'Procurement order price value',
    offerNotePriceValue: 'Offer note price value',
    invoicedStockNotePriceValue: 'Invoiced stock note price value',
    stockReportPriceValue: 'Stock report price value',
    retailPriceCalculationRetailValue: 'Retail price calculation retail value',
    retailPriceCalculationTaxValue: 'Retail price calculation tax value',
    retailPriceCalculationEntryPriceValue: 'Retail price calculation entry price value',
    retailPriceCalculationEntryTaxValue: 'Retail price calculation entry tax value',
    regularInvoiceTotalPriceValue: 'Regular invoice total price value',
    regularInvoiceBaseValue: 'Regular invoice base value',
    regularInvoiceTotalTaxValue: 'Regular invoice total tax value',
    regularInvoiceTax1Value: 'Regular invoice tax1 value',
    regularInvoiceTax2Value: 'Regular invoice tax2 value',
    regularInvoiceTax3Value: 'Regular invoice tax3 value',
    regularInvoiceAccommodationTaxValue: 'Regular invoice accommodation tax value',
    regularInvoiceDiscountValue: 'Regular invoice discount value',
    advanceInvoiceTotalPriceValue: 'Advance invoice total price value',
    advanceInvoiceBaseValue: 'Advance invoice base value',
    advanceInvoiceTotalTaxValue: 'Advance invoice total tax value',
    advanceInvoiceTax1Value: 'Advance invoice tax1 value',
    advanceInvoiceTax2Value: 'Advance invoice tax2 value',
    advanceInvoiceTax3Value: 'Advance invoice tax3 value',
    advanceInvoiceAccommodationTaxValue: 'Advance invoice accommodation tax value',
    advanceInvoiceDiscountValue: 'Advance invoice discount value',
    proformaInvoiceTotalPriceValue: 'Proforma invoice total price value',
    proformaInvoiceBaseValue: 'Proforma invoice base value',
    proformaInvoiceTotalTaxValue: 'Proforma invoice total tax value',
    proformaInvoiceTax1Value: 'Proforma invoice tax1 value',
    proformaInvoiceTax2Value: 'Proforma invoice tax2 value',
    proformaInvoiceTax3Value: 'Proforma invoice tax3 value',
    proformaInvoiceAccommodationTaxValue: 'Proforma invoice accommodation tax value',
    proformaInvoiceDiscountValue: 'Proforma invoice discount value',
    proformaDispatchedInvoiceTotalPriceValue: 'Proforma dispatched invoice total price value',
    proformaDispatchedInvoiceBaseValue: 'Proforma dispatched invoice base value',
    proformaDispatchedInvoiceTotalTaxValue: 'Proforma dispatched invoice total tax value',
    proformaDispatchedInvoiceTax1Value: 'Proforma dispatched invoice tax1 value',
    proformaDispatchedInvoiceTax2Value: 'Proforma dispatched invoice tax2 value',
    proformaDispatchedInvoiceTax3Value: 'Proforma dispatched invoice tax3 value',
    proformaDispatchedInvoiceDiscountValue: 'Proforma dispatched invoice discount value',
    'Total purchase gross value': '',
    'Total tax value': '',
    'Total sales gross value': '',
    'Total retail gross value': '',
    'Total retail tax value': '',
    'Total entry gross value': '',
    'Total entry tax value': '',
    'Base gross value': '',
    'Total tax valu': '',
    'Total tax1 value': '',
    'Total tax2 value': '',
    'Total tax3 value': '',
    'Total accommodation tax value': '',
    documentItem: 'Document item',
    glType: 'Type',
    glClass: 'Class',
    creditGLAccount: 'Credit account',
    debtGLAccount: 'Debt account',
    'Guests number': '',
    'New exception': '',
    'Expected change in supplies': '',
    'User suggested write off': '',
    'Confirmed write-off': '',
    'Suggest write off': '',
    'Possible write off': '',
    'Add item to warehouse': '',
    'Add new item': '',
    'Select type': '',
    'Supplies refill for the events': '',
    'Household warehouses': '',
    'Anticipated supply refill': '',
    'Are you sure you want to remove item': '',
    'Remove item': '',
    'Suggested write-off': '',
    'Write-off list': '',
    'Add a household item': '',
    'Select warehouse': '',
    quintupleUse: 'Five-person occupancy',
    sextupleUse: 'Six-person occupancy',
    septupleUse: 'Seven-person occupancy',
    ' octupleUse': 'Eight-person occupancy',
    ' nonupleUse': 'Nine-person occupancy',
    ' decupleUse': 'Ten-person occupancy',
    'Auto suggest write-off': '',
    'Dispatch to the laundry room': '',
    Dispatched: '',
    'Proforma shipping document': '',
    'goodsReceivingNote grossValue': 'Goods receiving note price value',
    'goodsReceivingNote taxValue': 'Goods receiving note tax value',
    'goodsDespatchNote grossValue': 'Goods despatch note price value',
    'goodsDespatchNote taxValue': 'Goods despatch note tax value',
    'writeOffGoods grossValue': 'Write-off goods price value',
    'interWarehouseTransfer grossValue': 'Inter warehouse transfer price value',
    'procurementOrder grossValue': 'Procurement order price value',
    'offerNote grossValue': 'Offer note price value',
    'invoicedStockNote grossValue': 'Invoiced stock note price value',
    'stockReport grossValue': 'Stock report price value',
    'retailPriceCalculation retailValue': 'Retail price calculation retail value',
    'retailPriceCalculation retailTaxValue': 'Retail price calculation tax value',
    'retailPriceCalculation entryPriceValue': 'Retail price calculation entry price value',
    'retailPriceCalculation entryTaxValue': 'Retail price calculation entry tax value',
    'regularInvoice totalPriceValue': 'Regular invoice total price value',
    'regularInvoice baseValue': 'Regular invoice base value',
    'regularInvoice totalTaxValue': 'Regular invoice total tax value',
    'regularInvoice tax1Value': 'Regular invoice tax1 value',
    'regularInvoice tax2Value': 'Regular invoice tax2 value',
    'regularInvoice tax3Value': 'Regular invoice tax3 value',
    'regularInvoice accommodationTaxValue': 'Regular invoice accommodation tax value',
    'regularInvoice discountValue': 'Regular invoice discount value',
    'advanceInvoice totalPriceValue': 'Advance invoice total price value',
    'advanceInvoice baseValue': 'Advance invoice base value',
    'advanceInvoice totalTaxValue': 'Advance invoice total tax value',
    'advanceInvoice tax1Value': 'Advance invoice tax1 value',
    'advanceInvoice tax2Value': 'Advance invoice tax2 value',
    'advanceInvoice tax3Value': 'Advance invoice tax3 value',
    'advanceInvoice accommodationTaxValue': 'Advance invoice accommodation tax value',
    'advanceInvoice discountValue': 'Advance invoice discount value',
    'proformaInvoice totalPriceValue': 'Proforma invoice total price value',
    'proformaInvoice baseValue': 'Proforma invoice base value',
    'proformaInvoice totalTaxValue': 'Proforma invoice total tax value',
    'proformaInvoice tax1Value': 'Proforma invoice tax1 value',
    'proformaInvoice tax2Value': 'Proforma invoice tax2 value',
    'proformaInvoice tax3Value': 'Proforma invoice tax3 value',
    'proformaInvoice accommodationTaxValue': 'Proforma invoice accommodation tax value',
    'proformaInvoice discountValue': 'Proforma invoice discount value',
    'proformaDispatchedInvoice totalPriceValue': 'proformaDispatchedInvoiceTotalPriceValue',
    'proformaDispatchedInvoice baseValue': 'Proforma dispatched invoice base value',
    'proformaDispatchedInvoice totalTaxValue': 'Proforma dispatched invoice total tax value',
    'proformaDispatchedInvoice tax1Value': 'Proforma dispatched invoice tax1 value',
    'proformaDispatchedInvoice tax2Value': 'Proforma dispatched invoice tax2 value',
    'proformaDispatchedInvoice tax3Value': 'Proforma dispatched invoice tax3 value',
    'proformaDispatchedInvoice discountValue': 'Proforma dispatched invoice discount value',
    isBeingUsedFlag: 'Is being used',
    grossValue: 'Gross value',
    _taxValue: 'Tax value',
    retailValue: 'Retail value',
    retailTaxValue: 'Retail tax value',
    _entryPriceValue: 'Entry price value',
    entryTaxValue: 'Entry tax value',
    baseValue: 'Base value',
    tax1Value: 'Tax1 value',
    tax2Value: 'Tax2 value',
    tax3Value: 'Tax3 value',
    accommodationTaxValue: 'Accommodation tax value',
    discountValue: 'Discount value',
    'Proforma dispatched invoices': '',
    'Total gross value': '',
    'Choose account': '',
    'PROFORMA DISPATCHED INVOICE': '',
    'Room cleaning': '',
    'DEFINITION EXAMPLE': '',
    'Reservation managed by aparment owner!': '',
    'All Controllers': '',
    earlyBird: '',
    confirmed: '',
    checkedIn: '',
    canceled: '',
    closed: '',
    autoBlocked: '',
    'Assign maid': '',
    'Assigned Maids': '',
    'If checked, the guest card is programmed to all controllers at once.': '',
    'Condo reservation': '',
    'Price change log': '',
    'Increase or decrease the price per person': '',
    'Pos Terminal - Attach Pos Items': '',
    'SAVE EDITED ITEMS': '',
    'Associated printer proxies': '',
    'Select printer': '',
    'Select printer proxy': '',
    rebateTotal: 'Rebate total',
    rebatePercentage: 'Rebate %',
    taxTotal: 'Tax total',
    salesPriceValue: 'Sales price value',
    invoiceUnitPrice: 'Invoice unit price',
    invoicePriceValue: 'Invoice price value',
    netoUnitPrice: 'Neto unit price',
    netoPriceValue: 'Neto price value',
    purchasePriceValue: 'Purchase price value',
    priceDifferencePercentage: 'Price difference',
    wholesaleUnitPrice: 'Wholesale unit price',
    wholesalePriceValue: 'Wholesale price value',
    retailUnitPrice: 'Retail unit price',
    retailPriceValue: 'Retail price value',
    rebateTotalValue: 'Rebate total value',
    allCustoms: 'Dependent costs',
    priceDifferenceValue: 'Price difference value',
    'Person responsible': '',
    ' price value': '',
    report: '',
    rebateValue: 'Rebate value',
    transportationValue: 'Transporation value',
    shippingValue: 'Shipping value',
    customsValue: 'Customs value',
    otherCostsValue: 'Other costs value',
    allDependetCosts: 'All dependet costs value',
    'SAVE FIRMWARE VERSION INFO TO DATABASE': '',
    'SAVE FIRMWARE VERSION INFO TO ALL CONTROLLERS IN DATABASE': '',
    'Save firmware version info to controllers in database': '',
    'Meas. unit': '',
    'Goods received': '',
    'Goods despatched': '',
    'Total stock obligation': '',
    'Daily consumption': '',
    'Inventory record': 'Recapitulation surplus/deficit',
    'Sku price': '',
    'Doc. num.': '',
    'Previous invoice': '',
    type: '',
    class: '',
    bankStatements: 'Bank statements',
    cashRegister: 'Cash register',
    'Types and Classes Definitions': '',
    _company: '',
    'Enter your email': '',
    'Please describe your problem in as much detail as possible. Our customer support team will immediately start working on the issue and notify you via the email address you provide in the form once the problem is resolved.':
        '',
    'There are active events whose controllers are not bound to any room. Close the events or bind the controller to a room.':
        '',
    'There are active events from the previous room of this controller.': '',
    'Close events': '',
    Printers: '',
    'Delete this active general ledger?': '',
    'All rooms': '',
    'Binded rooms': '',
    'Unbinded rooms': '',
    'Deselect all': '',
    'Apply new offer': '',
    cleaningTask: '',
    'Alarm and room mode interpreter': '',
    'Doc.name': '',
    Balance: '',
    debtBalance: 'Debt balance',
    creditBalance: 'Credit balance',
    'Account ': '',
    'from ': '',
    "Partner's balance": '',
    'Opening state on day': '',
    Transactions: '',
    'Closing state on day': '',
    'Balance state on day': '',
    'Select balance rule on report definition': '',
    Expresion: '',
    'Room beds (+ spare beds)': '',
    cardHolderInput: 'Card holder',
    sosInput: 'Sos',
    doorStateInput: 'Door state',
    windowStateInput: 'Window state',
    doNotDisturbInput: 'Do not disturb',
    thermostatInput1: 'Thermostat',
    fireAlarmInput: 'Fire alarm',
    smokeAlarmInput: 'Smoke alarm',
    motionAlarmInput: 'Motion alarm',
    floodAlarmInput: 'Flood alarm',
    minibarStateInput: 'Minibar',
    welcomeLightInput: 'Welcome light',
    cleaningServiceInput: 'Cleaning service',
    'The issue has been reported. Customer service will review your issue and begin resolving it.': '',
    'Report a new Issue': '',
    'Show completed': '',

    Afghanistan: '',
    'Åland Islands': '',
    Albania: '',
    Algeria: '',
    'American Samoa': '',
    Andorra: '',
    Angola: '',
    Anguilla: '',
    Antarctica: '',
    'Antigua and Barbuda': '',
    Argentina: '',
    Armenia: '',
    Aruba: '',
    Australia: '',
    Austria: '',
    Azerbaijan: '',
    Bahamas: '',
    Bahrain: '',
    Bangladesh: '',
    Barbados: '',
    Belarus: '',
    Belgium: '',
    Belize: '',
    Benin: '',
    Bermuda: '',
    Bhutan: '',
    'Bolivia (Plurinational State of)': '',
    'Bonaire, Sint Eustatius and Saba': '',
    'Bosnia and Herzegovina': '',
    Botswana: '',
    'Bouvet Island': '',
    Brazil: '',
    'British Indian Ocean Territory': '',
    'Brunei Darussalam': '',
    Bulgaria: '',
    'Burkina Faso': '',
    Burundi: '',
    'Cabo Verde': '',
    Cambodia: '',
    Cameroon: '',
    Canada: '',
    'Cayman Islands': '',
    'Central African Republic': '',
    Chad: '',
    Chile: '',
    China: '',
    'Cocos (Keeling) Islands': '',
    Colombia: '',
    Comoros: '',
    Congo: '',
    'Congo, Democratic Republic of the': '',
    'Cook Islands': '',
    'Costa Rica': '',
    "Côte d'Ivoire": '',
    Croatia: '',
    Cuba: '',
    Curaçao: '',
    Cyprus: '',
    Czechia: '',
    Denmark: '',
    Djibouti: '',
    Dominica: '',
    'Dominican Republic': '',
    Ecuador: '',
    Egypt: '',
    'El Salvador': '',
    'Equatorial Guinea': '',
    Eritrea: '',
    Estonia: '',
    Eswatini: '',
    Ethiopia: '',
    'Falkland Islands (Malvinas)': '',
    'Faroe Islands': '',
    Fiji: '',
    Finland: '',
    France: '',
    'French Guiana': '',
    'French Polynesia': '',
    'French Southern Territories': '',
    Gabon: '',
    Gambia: '',
    Georgia: '',
    Germany: '',
    Ghana: '',
    Gibraltar: '',
    Greece: '',
    Greenland: '',
    Grenada: '',
    Guadeloupe: '',
    Guam: '',
    Guatemala: '',
    Guernsey: '',
    Guinea: '',
    'Guinea-Bissau': '',
    Guyana: '',
    Haiti: '',
    'Heard Island and McDonald Islands': '',
    'Holy See': '',
    Honduras: '',
    'Hong Kong': '',
    Hungary: '',
    Iceland: '',
    India: '',
    Indonesia: '',
    'Iran (Islamic Republic of)': '',
    Iraq: '',
    Ireland: '',
    'Isle of Man': '',
    Israel: '',
    Italy: '',
    Jamaica: '',
    Japan: '',
    Jersey: '',
    Jordan: '',
    Kazakhstan: '',
    Kenya: '',
    Kiribati: '',
    "Korea (Democratic People's Republic of)": '',
    'Korea, Republic of': '',
    Kuwait: '',
    Kyrgyzstan: '',
    "Lao People's Democratic Republic": '',
    Latvia: '',
    Lebanon: '',
    Lesotho: '',
    Liberia: '',
    Libya: '',
    Liechtenstein: '',
    Lithuania: '',
    Luxembourg: '',
    Macao: '',
    Madagascar: '',
    Malawi: '',
    Malaysia: '',
    Maldives: '',
    Mali: '',
    Malta: '',
    'Marshall Islands': '',
    Martinique: '',
    Mauritania: '',
    Mauritius: '',
    Mayotte: '',
    Mexico: '',
    'Micronesia (Federated States of)': '',
    'Moldova, Republic of': '',
    Monaco: '',
    Mongolia: '',
    Montenegro: '',
    Montserrat: '',
    Morocco: '',
    Mozambique: '',
    Myanmar: '',
    Namibia: '',
    Nepal: '',
    Netherlands: '',
    'New Caledonia': '',
    'New Zealand': '',
    Nicaragua: '',
    Niger: '',
    Nigeria: '',
    Niue: '',
    'Norfolk Island': '',
    'North Macedonia': '',
    'Northern Mariana Islands': '',
    Norway: '',
    Oman: '',
    Pakistan: '',
    Palau: '',
    'Palestine, State of': '',
    Panama: '',
    'Papua New Guinea': '',
    Paraguay: '',
    Peru: '',
    Philippines: '',
    Pitcairn: '',
    Poland: '',
    Portugal: '',
    'Puerto Rico': '',
    Qatar: '',
    Slovakia: '',
    Slovenia: '',
    'Solomon Islands': '',
    Somalia: '',
    'South Africa': '',
    'South Georgia and the South Sandwich Islands': '',
    'South Sudan': '',
    Spain: '',
    'Sri Lanka': '',
    Sudan: '',
    Suriname: '',
    'Viet Nam': '',
    Afghan: '',
    'Åland Island': '',
    Albanian: '',
    Algerian: '',
    'American Samoan': '',
    Andorran: '',
    Angolan: '',
    Anguillan: '',
    Antarctic: '',
    'Antiguan or Barbudan': '',
    Argentine: '',
    Armenian: '',
    Aruban: '',
    Australian: '',
    Austrian: '',
    'Azerbaijani, Azeri': '',
    Bahamian: '',
    Bahraini: '',
    Bangladeshi: '',
    Barbadian: '',
    Belgian: '',
    Belizean: '',
    'Beninese, Beninois': '',
    'Bermudian, Bermudan': '',
    Bhutanese: '',
    Bolivian: '',
    Bonaire: '',
    'Bosnian or Herzegovinian': '',
    'Motswana, Botswanan': '',
    Brazilian: '',
    BIOT: '',
    Bruneian: '',
    Bulgarian: '',
    Burkinabé: '',
    Burundian: '',
    'Cabo Verdean': '',
    Cambodian: '',
    Cameroonian: '',
    Canadian: '',
    Caymanian: '',
    'Central African': '',
    Chadian: '',
    Chilean: '',
    Chinese: '',
    'Christmas Island': '',
    'Cocos Island': '',
    Colombian: '',
    'Comoran, Comorian': '',
    Congolese: '',
    'Cook Island': '',
    'Costa Rican': '',
    Ivorian: '',
    Cuban: '',
    Curaçaoan: '',
    Cypriot: '',
    Czech: '',
    Danish: '',
    Djiboutian: '',
    Dominican: '',
    Ecuadorian: '',
    Egyptian: '',
    Salvadoran: '',
    'Equatorial Guinean': '',
    Eritrean: '',
    Ethiopian: '',
    'Falkland Island': '',
    Faroese: '',
    Finnish: '',
    'French Guianese': '',
    'French Polynesian': '',
    Gabonese: '',
    Gambian: '',
    Ghanaian: '',
    Greek: '',
    Greenlandic: '',
    Grenadian: '',
    Guamanian: '',
    Guatemalan: '',
    Guinean: '',
    'Bissau-Guinean': '',
    Guyanese: '',
    Haitian: '',
    'Heard Island or McDonald Islands': '',
    Vatican: '',
    Honduran: '',
    'Hong Kongese': '',
    Hungarian: '',
    Icelandic: '',
    Indian: '',
    Indonesian: '',
    Iranian: '',
    Iraqi: '',
    Irish: '',
    Manx: '',
    Israeli: '',
    Italian: '',
    Jamaican: '',
    Japanese: '',
    Jordanian: '',
    Kazakhstani: '',
    Kenyan: '',
    'I-Kiribati': '',
    'North Korean': '',
    'South Korean': '',
    Kuwaiti: '',
    Kyrgyzstani: '',
    Lebanese: '',
    Basotho: '',
    Liberian: '',
    Libyan: '',
    Lithuanian: '',
    'Macanese, Chinese': '',
    Macedonian: '',
    Malagasy: '',
    Malawian: '',
    Malaysian: '',
    Maldivian: '',
    'Malian, Malinese': '',
    Maltese: '',
    'Martiniquais, Martinican': '',
    Mauritanian: '',
    Mauritian: '',
    Mahoran: '',
    Mexican: '',
    Micronesian: '',
    Moldovan: '',
    'Monégasque, Monacan': '',
    Mongolian: '',
    Montenegrin: '',
    Montserratian: '',
    Moroccan: '',
    Mozambican: '',
    Namibian: '',
    Nauruan: '',
    'Nepali, Nepalese': '',
    'Dutch, Netherlandic': '',
    'New Caledonian': '',
    'New Zealand, NZ': '',
    Nicaraguan: '',
    Nigerien: '',
    Nigerian: '',
    Niuean: '',
    'Northern Marianan': '',
    Norwegian: '',
    Omani: '',
    Pakistani: '',
    Palauan: '',
    Palestinian: '',
    Panamanian: '',
    'Papua New Guinean, Papuan': '',
    Paraguayan: '',
    Peruvian: '',
    'Philippine, Filipino': '',
    'Pitcairn Island': '',
    Polish: '',
    Portuguese: '',
    'Puerto Rican': '',
    Réunion: '',
    Romania: '',
    'Russian Federation': '',
    Rwanda: '',
    'Saint Barthélemy': '',
    'Saint Helena, Ascension and Tristan da Cunha': '',
    'Saint Kitts and Nevis': '',
    'Saint Lucia': '',
    'Saint Martin (French part)': '',
    'Saint Pierre and Miquelon': '',
    'Saint Vincent and the Grenadines': '',
    Samoa: '',
    'San Marino': '',
    'Sao Tome and Principe': '',
    'Saudi Arabia': '',
    Senegal: '',
    Serbia: '',
    Seychelles: '',
    'Sierra Leone': '',
    Singapore: '',
    'Sint Maarten (Dutch part)': '',
    'Svalbard and Jan Mayen': '',
    Swaziland: '',
    Sweden: '',
    Switzerland: '',
    'Syrian Arab Republic': '',
    'Taiwan, Province of China': '',
    Tajikistan: '',
    'Tanzania, United Republic of': '',
    Thailand: '',
    'Timor-Leste': '',
    Togo: '',
    Tokelau: '',
    'Trinidad and Tobago': '',
    Tunisia: '',
    Turkey: '',
    Turkmenistan: '',
    'Turks and Caicos Islands': '',
    Tuvalu: '',
    Uganda: '',
    Ukraine: '',
    'United Arab Emirates': '',
    'United Kingdom of Great Britain and Northern Ireland': '',
    'United States Minor Outlying Islands': '',
    'United States of America': '',
    Uruguay: '',
    Uzbekistan: '',
    Vanuatu: '',
    'Venezuela (Bolivarian Republic of)': '',
    Vietnam: '',
    'Virgin Islands (British)': '',
    'Virgin Islands (U.S.)': '',
    'Wallis and Futuna': '',
    'Western Sahara': '',
    Yemen: '',
    Zambia: '',
    Zimbabwe: '',

    Afrikaans: '',
    Abkhaz: '',
    Afar: '',
    Akan: '',
    Amharic: '',
    Arabic: '',
    Aragonese: '',
    Assamese: '',
    Avaric: '',
    Avestan: '',
    Aymara: '',
    Azerbaijani: '',
    Bambara: '',
    Bashkir: '',
    Basque: '',
    Belarusian: '',
    Bengali: '',
    Bihari: '',
    Bislama: '',
    Bosnian: '',
    Breton: '',
    Burmese: '',
    Catalan: '',
    Chamorro: '',
    Chechen: '',
    Chichewa: '',
    Chuvash: '',
    Cornish: '',
    Corsican: '',
    Cree: '',
    Croatian: '',
    Divehi: '',
    Dutch: '',
    English: '',
    Esperanto: '',
    Estonian: '',
    Ewe: '',
    Fijian: '',
    French: '',
    Fula: '',
    Galician: '',
    Guarani: '',
    Gujarati: '',
    Hausa: '',
    Hebrew: '',
    Herero: '',
    Hindi: '',
    HiriMotu: '',
    Interlingua: '',
    Interlingue: '',
    Igbo: '',
    Inupiaq: '',
    Ido: '',
    Inuktitut: '',
    Javanese: '',
    Kalaallisut: '',
    Kannada: '',
    Kanuri: '',
    Kashmiri: '',
    Kazakh: '',
    Khmer: '',
    Kikuyu: '',
    Kinyarwanda: '',
    Kirghiz: '',
    Komi: '',
    Kongo: '',
    Korean: '',
    Kurdish: '',
    Kwanyama: '',
    Latin: '',
    Luxembourgish: '',
    Luganda: '',
    Limburgish: '',
    Lingala: '',
    Lao: '',
    LubaKatanga: '',
    Latvian: '',
    Malay: '',
    Malayalam: '',
    Māori: '',
    Marathi: '',
    Marshallese: '',
    Navajo: '',
    NorwegianBokmål: '',
    NorthNdebele: '',
    Nepali: '',
    Ndonga: '',
    NorwegianNynorsk: '',
    Nuosu: '',
    SouthNdebele: '',
    Occitan: '',
    Ojibwe: '',
    OldChurchSlavonic: '',
    Oromo: '',
    Oriya: '',
    Ossetian: '',
    Panjabi: '',
    Pāli: '',
    Persian: '',
    Pashto: '',
    Quechua: '',
    Romansh: '',
    Kirundi: '',
    Romanian: '',
    Russian: '',
    Sanskrit: '',
    Sardinian: '',
    Sindhi: '',
    NorthernSami: '',
    Samoan: '',
    Sango: '',
    Serbian: '',
    ScottishGaelic: '',
    Shona: '',
    Sinhala: '',
    Slovak: '',
    Slovene: '',
    Somali: '',
    SouthernSotho: '',
    Spanish: '',
    Sundanese: '',
    Swahili: '',
    Swati: '',
    Swedish: '',
    Tamil: '',
    Telugu: '',
    Tajik: '',
    Thai: '',
    Tigrinya: '',
    Tibetan: '',
    Turkmen: '',
    Tagalog: '',
    Tswana: '',
    Tonga: '',
    Turkish: '',
    Tsonga: '',
    Tatar: '',
    Twi: '',
    Tahitian: '',
    Uighur: '',
    Ukrainian: '',
    Urdu: '',
    Uzbek: '',
    Venda: '',
    Vietnamese: '',
    Volapük: '',
    Walloon: '',
    Welsh: '',
    Wolof: '',
    WesternFrisian: '',
    Xhosa: '',
    Yiddish: '',
    Yoruba: '',
    Zhuang: '',
    'Add new type or class': '',
    'Edit type or class': '',
    roomServiceInput: 'Room service',
    docNumber: 'Document number',
    externalNumber: 'external number',
    currencyId: 'currency',
    exchangeRate: 'exchange rate',
    issueDate: 'issue date',
    postingDate: 'posting date',
    dueDate: 'due date',
    companyId: 'company',
    userUuid: 'user',
    note: 'note',
    'Select cells for accounting journal headers': '',
    debtForeign: 'debt (foreign currency)',
    creditForeign: 'credit (foreign currency)',
    'Date select': '',
    'Smart Room Controllers': '',
    'Search by SRC name, room name or proxy name ': '',
    'Move reservation from room': '',
    'to room': '',
    'Room transfer': '',
    'Tax total': '',
    'Stock value adjustment': '',
    'Add new task': '',
    'Clear room access codes': '',
    'Select the type/s of items for which you want to create a calculation': '',
    'Select item types': '',
    'CONFIRM AND CREATE CALCULATION': '',
    'CONFIRM AND ADD REFERENCE': '',
    purchaseValueWithTax: 'Purchase value with tax',
    'Measure unit': '',
    Neto: '',
    'All customs no tax': '',
    Purchase: '',
    'Sales value no tax': '',
    Retail: '',
    'Price no tax': '',
    'Value no tax': '',
    'Value with tax': '',
    'Price with tax': '',
    'Mandatory company select in general ledger': '',
    'Show tiles view': '',
    'Show list view': '',
    Goods: '',
    'Services & accommodation': '',
    totalTransportation: 'Total transportation',
    totalShipping: 'Total shipping',
    totalCustoms: 'Total customs',
    totalOtherCosts: 'Total other costs',
    totalInvoicePriceValue: 'Total invoice price value',
    'All items': '',
    'Stock card': '',
    'Inbound qty.': '',
    'Outbound qty.': '',
    'Inbound price': '',
    'Outbound price': '',
    'Inv.num.': '',
    'Search item': '',
    'Create Customer/Company on POS': '',
    'Number decimal separator': '',
    Dot: '',
    Comma: '',
    'REACTIVATE MPC': '',
    'REACTIVATE DOCUMENT': '',
    companyMandatory: '',
    'Start shift': 'Shift start',
    'End shift': 'Shift end',
    'Based on shift start': 'based on shift start',
    'Funds received':'',
};

export default TranslationsEng;
